<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        Add Event
      </b-button> -->
      <b-row class="justify-content-end">
        <b-col
          class="search-location-bar"
          sm="12"
          md="4"
          xl="4"
        >

          <!-- basic -->
          <b-form-group
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model="filterByLocation"
              placeholder="Filter by Location"
              @keyup="filterLocation()"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col
          sm="6"
          md="4"
          xl="4"
        > -->
        <!-- Filter by Assigned Staff -->
        <!-- <b-form-group>
            <v-select
              v-model="selected1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="option1"
              class="select1"
              placeholder="Filter by Assigned Staff"
            />
          </b-form-group>
        </b-col> -->

        <!-- <b-col
          sm="6"
          md="4"
          xl="4"
        > -->
        <!-- Filter by Tag -->
        <!-- <b-form-group>
            <v-select
              v-model="selected2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="tag"
              :options="option2"
              class="select2"
              placeholder="Filter by Tag"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
      <div class="w-100">
        <!-- <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars</span>
        </h5> -->
        <b-form-group>
          <b-form-checkbox
            v-model="checkAll"
            class="mt-1 show-all"
          >
            Show All
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            inline
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.id"
              name="event-filter"
              :value="item.id"
              :class="`custom-control-${item.class} mb-1 mt-1`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import { ref } from '@vue/composition-api'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BButton,
    // BImg,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    // vSelect,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
      // selected1,
      // option1,
      // selected2,
      // option2,
    } = useCalendarSidebar()

    const filterByLocation = ref()
    const filterLocation = () => {
      store.commit('calendar/SET_SELECTED_FILTERLOCATION', filterByLocation.value)
    }

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
      filterByLocation,
      // selected1,
      // option1,
      // selected2,
      // option2,
      filterLocation,
    }
  },
}
</script>

<style>
.select1, .select2{
  background: rgb(255, 255, 255);
}
.select1 input::placeholder,
.select2 input::placeholder {
  color: #B1BAC4!important;
  opacity: 1;
}
.show-all{
  float: left;
  margin-right: 10px;
}
.custom-control-scheduled .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-scheduled .custom-control-input:active ~ .custom-control-label::before {
    border-color: #569099 !important;
    background-color: #569099 !important;
}
.custom-control-scheduled .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-scheduled .custom-control-input:active ~ .custom-control-label::before {
    border-color: #01BCD6 !important;
    background-color: #01BCD6 !important;
}
.custom-control-in-progress .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-in-progress .custom-control-input:active ~ .custom-control-label::before {
    border-color: #FFCE2D !important;
    background-color: #FFCE2D !important;
}
.custom-control-completed .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-completed .custom-control-input:active ~ .custom-control-label::before {
    border-color: #B7CF3E !important;
    background-color: #B7CF3E !important;
}
.custom-control-m-clock-in .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-m-clock-in .custom-control-input:active ~ .custom-control-label::before,
.custom-control-m-clock-out .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-m-clock-out .custom-control-input:active ~ .custom-control-label::before {
    border-color: #EB6566 !important;
    background-color: #EB6566 !important;
}
.custom-control-open-shift .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-open-shift .custom-control-input:active ~ .custom-control-label::before {
    border-color: #E73684 !important;
    background-color: #E73684 !important;
}
.custom-control-cancelled-client .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-cancelled-client .custom-control-input:active ~ .custom-control-label::before {
    border-color: #B1BAC4 !important;
    background-color: #B1BAC4 !important;
}
.custom-control-cancelled-caregiver .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-cancelled-caregiver .custom-control-input:active ~ .custom-control-label::before {
    border-color: #FF7D01 !important;
    background-color: #FF7D01 !important;
}
.custom-control-tentative-not-echeduled .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-tentative-not-echeduled .custom-control-input:active ~ .custom-control-label::before {
    border-color: #516F90 !important;
    background-color: #516F90 !important;
}
.custom-control-pending-confirmation .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-pending-confirmation .custom-control-input:active ~ .custom-control-label::before {
    border-color: #7566E8 !important;
    background-color: #7566E8 !important;
}
.custom-control-attention-required .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-attention-required .custom-control-input:active ~ .custom-control-label::before {
    border-color: #EB6566 !important;
    background-color: #EB6566 !important;
}
</style>
