<template>
  <div>
    <div>
      <!-- modal -->
      <b-modal
        id="modal-primary"
        ref="requestApproveModal"
        centered
        hide-footer
        title="Approve Pending Confirmation"
      >
        <div class="d-block text-center mb-5">
          <v-select
            v-model="toApproveCaregiver"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="pendingCaregiverOptions"
            :reduce="option => option.cid"
            placeholder="Select Caregiver"
            @input="checkCaregiverAvailability"
          />
        </div>
        <div class="d-flex flex-row-reverse">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3 ml-1"
            variant="primary"
            @click="approveRequest"
          >
            Approve
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3 ml-1"
            variant="outline-secondary"
            @click="hideRequestApproveModal"
          >
            Close
          </b-button>
        </div>
      </b-modal>

      <!-- pick what to update modal -->
      <b-modal
        id="modal-primary"
        ref="chooseRecurrModal"
        centered
        hide-footer
        size="xs"
        title="Open Recurring Shift"
      >
        <div class="d-block mb-0">
          This Shift is part of a recurring series. What do you want to open?
          <div class="demo-inline-spacing ml-1">
            <b-form-radio
              v-model="recurrType"
              name="some-radios"
              value="single"
            >
              Just this one
            </b-form-radio>
            <b-form-radio
              v-model="recurrType"
              name="some-radios"
              value="all"
            >
              The entire series
            </b-form-radio>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3 ml-1"
            variant="primary"
            @click="openRecurrShift(recurrType)"
          >
            Continue
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3 ml-1"
            variant="outline-secondary"
            @click="chooseRecurrModal.hide()"
          >
            Close
          </b-button>
        </div>
      </b-modal>

      <!-- pick what to update modal -->
      <b-modal
        id="modal-primary"
        ref="updateRecurrModal"
        centered
        hide-footer
        title="Update Recurring Shift"
      >
        <div class="d-block mb-0 mt-1 px-1">
          This shift is part of a recurring series. Modifying this occurence will make it independent shift. Would you like to proceed with the changes for this one?
        </div>
        <div class="d-flex flex-row-reverse">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1 ml-1"
            variant="primary"
            @click="continueUpdateSingleRecurr()"
          >
            Yes
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1 ml-1"
            variant="outline-secondary"
            @click="updateRecurrModal.hide()"
          >
            No
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="row no-gutters d-inline">
      <!-- Sidebar -->
      <div
        class="app-calendar-sidebar border-0 w-100"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative border">
        <b-overlay
          :show="$store.state.calendar.isLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="lg"
        >
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-1">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </b-overlay>
      </div>

      <!-- Calendar GanntWeekView -->
      <div
        v-if="$store.state.calendar.ganntWeekView"
        class="col position-relative border custom-view mb-3"
      >
        <div class="card shadow-none border-0 mb-0 rounded-0 gantt-card">
          <div class="card-body pb-0 mb-10 gantt-body">
            <gannt-week-view
              :gantt-day-view-data="ganttDayViewData"
              @gantt-add-event="ganttAddShift"
              @gantt-send-message="ganttSendMessage"
              @gantt-edit-shift="ganttEditShift"
            />
          </div>
        </div>
      </div>

      <!-- Calendar GanntDayView -->
      <div
        v-if="$store.state.calendar.ganntDayView"
        class="col position-relative border custom-view mb-3"
      >
        <div class="card shadow-none border-0 mb-0 rounded-0 gantt-card">
          <div class="card-body pb-0 mb-10 gantt-body">
            <gannt-day-view
              :gantt-day-view-data="ganttDayViewData"
              @gantt-add-event="ganttAddShift"
              @gantt-send-message="ganttSendMessage"
              @gantt-edit-shift="ganttEditShift"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :all-temp-fetched-data="allTempFetchedData"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
        @update-event-modal="updateEventModal"
        @send-message="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import {
  BModal, VBModal, BButton, BOverlay, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import GanntWeekView from './GanntWeekView.vue'
import GanntDayView from './GanntDayView.vue'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    GanntWeekView,
    GanntDayView,
    BModal,
    vSelect,
    BButton,
    BOverlay,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    Ripple,
    VBModal,
    vSelect,
  },
  unmounted() {
    this.style.remove()
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    document.querySelector('#app').style.overflow = 'scroll'
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    // const openRecurrShift = type => {
    //   console.log(type)
    // }

    const {
      refCalendar,
      requestApproveModal,
      chooseRecurrModal,
      updateRecurrModal,
      continueUpdateSingleRecurr,
      recurrType,
      openRecurrShift,
      hideRequestApproveModal,
      approveRequest,
      pendingCaregiverOptions,
      toApproveCaregiver,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      updateEventModal,
      sendMessage,
      removeEvent,
      fetchEvents,
      allTempFetchedData,
      refetchEvents,
      calendarOptions,
      ganttDayViewData,
      sendMessage1,
      ganttAddShift,
      ganttSendMessage,
      ganttEditShift,

      checkCaregiverAvailability,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      requestApproveModal,
      chooseRecurrModal,
      updateRecurrModal,
      continueUpdateSingleRecurr,
      recurrType,
      openRecurrShift,
      hideRequestApproveModal,
      approveRequest,
      pendingCaregiverOptions,
      toApproveCaregiver,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      updateEventModal,
      sendMessage,
      removeEvent,
      refetchEvents,
      allTempFetchedData,
      calendarOptions,
      ganttDayViewData,
      sendMessage1,
      ganttAddShift,
      ganttSendMessage,
      ganttEditShift,

      checkCaregiverAvailability,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>

<style scoped>
.app-calendar-sidebar{
  background: transparent;
}
.gantt-card{
  overflow: auto;
}
.gantt-body{
  min-width: 800px;
}
</style>
<style>
.title-event{
  overflow: hidden !important;
  padding: 0px;
  margin: 0px;
}
[dir] .fc .fc-daygrid-day-number{
  pointer-events: none;
}
@media only screen and (max-width: 820px) {
  #g-gantt-chart[data-v-57d4915b] {
    font-size: 12px;
  }
  [dir=ltr] .label-span[data-v-1b2d45d5], [dir=ltr] .label-span[data-v-093dcaf5] {
      padding-left: 5px;
  }
 .caregiver-label{
    font-size: 10px !important;
    margin-left: 5px !important;
  }
 .fa-envelope[data-v-093dcaf5], .fa-envelope[data-v-1b2d45d5]{
    font-size: 10px !important;
  }
}
</style>
