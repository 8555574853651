/* eslint-disable no-unreachable */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
// import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import axios from '@axios'
import SockJS from 'sockjs-client'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)
  const requestApproveModal = ref(null)
  const chooseRecurrModal = ref(null)
  const updateRecurrModal = ref(null)
  const recurrType = ref('single')
  const pendingCaregiverOptions = ref([])
  const toApproveCaregiver = ref(null)
  const currentDate = ref(moment(new Date()).format('YYYY-MM-DD'))

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    1: 'scheduled',
    2: 'in-progress',
    3: 'completed',
    4: 'm-clock-in',
    5: 'm-clock-out',
    6: 'open-shift',
    7: 'c-client',
    8: 'c-caregiver',
    9: 'tentative-not-scheduled',
    10: 'pending-confirmation',
    11: 'attention-required',
  }

  // Get user current Email
  const uData = JSON.parse(localStorage.getItem('userData'))

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    extendedProps: {
      status: null,
      statusOptions: '',
      recurrence: false,
      recurrence_identifier: false,
      recurrence_pattern: 'daily',
      dailyPattern: {
        pattern: 'every',
        day: '1',
      },
      weeklyPattern: {
        every_week: '1',
        days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      },
      monthlyPattern: {
        pattern: 'pattern1',
        input_one: '',
        input_two: '',
        selected1: [],
        selected2: [],
        input_three: '',
      },
      yearlyPattern: {
        recur_every: '',
        pattern: 'pattern1',
        input_one: '',
        input_two: '',
        selected1: [],
        selected2: [],
        selected3: [],
        selected4: [],
      },
      pattern_option: {},
      repeat_every: null,
      repeat_on: [],
      repeat_until: '', // new Date().toISOString().slice(0, 10),
      client: '',
      caregiver: '',
      caregiver_fullname: '',
      client_fullname: '',
      location: '',
      caregiverTask: [],
      pending: false,
      shift_tasks: [],
      shift_type: false,
      orig_start: '',
      orig_end: '',

      // For send mail data
      delivery: null,
      userEmail: uData.email,
      caregiverEmail: '',
      relateTo: '',
      clientEmail: '',
      subject: '',
      message: '',
      signature: '',
      files: null,
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  // eslint-disable-next-line no-unused-vars
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        status,
        statusOptions,
        recurrence,
        recurrence_identifier,
        recurrence_pattern,
        dailyPattern,
        weeklyPattern,
        monthlyPattern,
        yearlyPattern,
        pattern_option,
        repeat_every,
        repeat_on,
        repeat_until,
        client,
        caregiver,
        caregiver_fullname,
        client_fullname,
        location,
        caregiverTask,
        pending,
        shift_tasks,
        shift_type,
        orig_start,
        orig_end,

        // For send mail data
        delivery,
        userEmail,
        caregiverEmail,
        relateTo,
        clientEmail,
        subject,
        message,
        signature,
        files,
      },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        status,
        statusOptions,
        recurrence,
        recurrence_identifier,
        recurrence_pattern,
        dailyPattern,
        weeklyPattern,
        monthlyPattern,
        yearlyPattern,
        pattern_option,
        repeat_every,
        repeat_on,
        repeat_until,
        client,
        caregiver,
        caregiver_fullname,
        client_fullname,
        location,
        caregiverTask,
        pending,
        shift_tasks,
        shift_type,
        orig_start,
        orig_end,

        // For send mail data
        delivery,
        userEmail,
        caregiverEmail,
        relateTo,
        clientEmail,
        subject,
        message,
        signature,
        files,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(response => {
      if (response.data.response.status == 200 && response.data.response.data) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        // eslint-disable-next-line no-use-before-define
        refetchEvents()
        // eslint-disable-next-line no-use-before-define
        fetchganntDayViewData()
      }
    })
  }

  const sock = new SockJS('https://socket.motivit.com:443/echo')
  const sockId = 'coc-n01'
  const sockId1 = 'coc-dw01'

  const getSockMsg = sockMsg => {
    // console.log(sockMsg)
    if (sockMsg.id == 'coc-n01') {
      // Trigger reload or something here
      refetchEvents()
      // eslint-disable-next-line no-use-before-define
      fetchganntDayViewData()
    }
  }

  const sendSockMsg = sockMsg => {
    sock.send(JSON.stringify({ id: sockId, msg: sockMsg }))
    sock.send(JSON.stringify({ id: sockId1, msg: sockMsg }))
  }

  const initializeSockJs = () => {
    sock.onopen = () => {
      // console.log('Connection established.')
    }

    sock.onmessage = sockEevent => {
      const data = JSON.parse(sockEevent.data)
      if (data.id == sockId || data.id == sockId1) {
        getSockMsg(data)
        // console.log(data)
      }
    }

    sock.onclose = () => {
      // console.warn('Connection closed.')
      SockJS.call(sock, sock.url)
    }
  }
  initializeSockJs()
  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    // console.log('non recurr')
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      if (response.data.response.status == 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Shift Updated',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        sendSockMsg(true)
        // eslint-disable-next-line no-use-before-define
        refetchEvents()
        // eslint-disable-next-line no-use-before-define
        fetchganntDayViewData()
      }
      // const propsToUpdate = ['id', 'title', 'url']
      // const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      // updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }
  let eventDataRecurr = {}
  const updateEventModal = eventData => {
    // console.log('update event modal')
    eventDataRecurr = eventData
    // console.log(eventDataRecurr)
    updateRecurrModal.value.show()
  }

  const continueUpdateSingleRecurr = () => {
    // console.log('test')
    // console.log(eventDataRecurr)
    eventDataRecurr.start = moment(eventDataRecurr.start).format('YYYY-MM-DD HH:mm:ss')
    eventDataRecurr.end = moment(eventDataRecurr.end).format('YYYY-MM-DD HH:mm:ss')
    updateEvent(eventDataRecurr)
    updateRecurrModal.value.hide()
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)
  const filterAction = ref(false)
  const dontOverlay = ref(false)
  const filterGanntAction = ref(false)

  watch(selectedCalendars, () => {
    filterGanntAction.value = true // activate gannt data filtering
    filterAction.value = true // activate fullcalendar filtering
    dontOverlay.value = true // dont activate overlay
    refetchEvents()
    fetchganntDayViewData()
  })

  const filterByLocation = computed(() => store.state.calendar.filterByLocation)
  watch(filterByLocation, () => {
    filterGanntAction.value = true // activate gannt data filtering
    filterAction.value = true // activate fullcalendar filtering
    dontOverlay.value = true // dont activate overlay
    refetchEvents()
    fetchganntDayViewData()
  })
  const selected1 = computed(() => store.state.calendar.selected1)
  const selected2 = computed(() => store.state.calendar.selected2)
  watch(selected1, () => {
    filterGanntAction.value = true // activate gannt data filtering
    filterAction.value = true // activate fullcalendar filtering
    dontOverlay.value = true // dont activate overlay
    refetchEvents()
    fetchganntDayViewData()
  })
  watch(selected2, () => {
    filterGanntAction.value = true // activate gannt data filtering
    filterAction.value = true // activate fullcalendar filtering
    dontOverlay.value = true // dont activate overlay
    refetchEvents()
    fetchganntDayViewData()
  })

  // ---------------------------------------------------------------------------------------------
  // MODALS
  const checkCaregiverAvailability = caregiverId => {
    const caregiverData = pendingCaregiverOptions.value.filter(c => c.caregiver_id == caregiverId)
    // console.log(caregiverId)
    const shiftData = Object.values(allTempFetchedData.value).filter(FetchedShiftData => (FetchedShiftData.caregiver == caregiverId && FetchedShiftData.status != '10' && FetchedShiftData.status != '8' && FetchedShiftData.status != '7' && FetchedShiftData.status != '3') && ((moment(FetchedShiftData.start) <= moment(event.value.start) && moment(event.value.start) < moment(FetchedShiftData.end)) || (moment(FetchedShiftData.start) < moment(event.value.end) && moment(event.value.end) < moment(FetchedShiftData.end))))
    // console.log(shiftData)
    if (shiftData.length != 0) {
      toast({
        component: ToastificationContent,
        props: {
          // title: `${caregiverData[0].name} is not Available! Please Select Another Caregiver.`,
          title: `${caregiverData[0].name} is already booked. Please wait for another Caregiver Request.`,
          icon: 'XIcon',
          variant: 'danger',
        },
      })
      toApproveCaregiver.value = ''
      return false
    }
    return true
  }
  const eventPass = ref(null)
  const hideRequestApproveModal = () => {
    requestApproveModal.value.hide()
  }
  const openRequestApproveModal = eventData => {
    toApproveCaregiver.value = null
    requestApproveModal.value.show()
    eventPass.value = eventData
  }
  const approveRequest = () => {
    // console.log('TEST APPROVED')
    event.value.extendedProps.caregiver = toApproveCaregiver.value
    const pendingData = pendingCaregiverOptions.value.filter(p => p.cid == toApproveCaregiver.value)

    if (checkCaregiverAvailability(toApproveCaregiver.value)) {
      // event.value.start = new Date(event.value.start).toLocaleDateString()
      // console.log(eventPass.value)
      axios
        .post(`${Vue.prototype.$apiUrl2}calendar/approvePendingConfirmationShift`, {
          param: {
            shiftEvent: event.value,
            userData: uData,
            pendingShift: pendingData.length > 0 ? pendingData[0] : null,
          },
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(response => {
          if (response.data.response.data) {
            refetchEvents()
            sendSockMsg(true)
            Swal.fire({
              icon: 'success',
              title: 'Sucess!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            requestApproveModal.value.hide()
          }
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }

  const openRecurrShift = type => {
    // console.log(type)
    // console.log(event.value)
    event.value.extendedProps.recurr_type = type
    chooseRecurrModal.value.hide()
    isEventHandlerSidebarActive.value = true
  }
  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const allFetchedData = ref([])
  const allTempFetchedData = ref([])
  const fetchEvents = (info, successCallback) => {
    if (dontOverlay.value == false) store.commit('calendar/SHOW_OVERLAY', true)
    // If there's no info => Don't make useless API call
    if (!info) return

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value.join(','),
        status: selectedCalendars.value,
        filter1: filterByLocation.value,
        filter2: selected1.value,
        filter3: selected2.value,
        userEmail: uData.email,
        allData: allFetchedData.value,
        allDataTemp: allTempFetchedData.value,
        filterAction: filterAction.value,
        current_date: currentDate.value,
      })
      .then(response => {
        if (response.data.response.data) {
          store.commit('calendar/SHOW_OVERLAY', false)
        }
        allFetchedData.value = response.data.response.data // filtered data
        // console.log(allFetchedData.value)
        successCallback(response.data.response.data)
        if (filterAction.value == false) {
          allTempFetchedData.value = response.data.response.data // Temp storage for all non-filtered data
          // console.log(allTempFetchedData.value)
        }
        filterAction.value = false
        dontOverlay.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getCurrentEventData = myEvent => {
    event.value = grabEventDataFromEventApi(myEvent)
    event.value.extendedProps.message = ''
  }

  // eslint-disable-next-line no-unused-vars
  const sendMessage = eventData => {
    // console.log(eventData)
    const formData = new FormData()
    // formData.append('file', eventData.extendedProps.files)
    if (eventData.extendedProps.files != undefined) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventData.extendedProps.files.length; i++) {
        const file = eventData.extendedProps.files[i]
        formData.append(`files[${i}]`, file)
      }
    }
    // eslint-disable-next-line no-plusplus

    const data = JSON.stringify({
      delivery: eventData.extendedProps.delivery,
      caregiverEmail: eventData.extendedProps.caregiverEmail,
      clientEmail: eventData.extendedProps.clientEmail,
      userEmail: eventData.extendedProps.userEmail,
      relatesTo: eventData.extendedProps.relatesTo,
      subject: eventData.extendedProps.subject,
      message: eventData.extendedProps.message,
      signature: eventData.extendedProps.signature,
    })
    formData.append('data', data)

    axios
      .post(`${Vue.prototype.$apiUrl2}calendar/uploadAttachment`,
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
      .then(response => {
        // console.log('upload response', response.data)
      })
      .catch(() => {
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'prev,next, title',
      end: 'dayGridDay,dayGridWeek,dayGridMonth',
    },
    events: fetchEvents,

    // showNonCurrentDates: false,
    fixedWeekCount: false,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.status]
      // eslint-disable-next-line no-underscore-dangle
      // console.log(calendarEvent._def.extendedProps)
      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventContent({ event: calendarEvent }) {
      // console.log(calendarEvent)
      // // eslint-disable-next-line no-underscore-dangle
      // const text = `<i class="fas fa-envelope"></i> ${calendarEvent._def.title}`
      // return {
      //   html: text,
      // }

      let arrayOfDomNodes = []
      // image event
      const imgEventWrap = document.createElement('i')
      imgEventWrap.classList = 'fas fa-envelope'
      imgEventWrap.innerHTML = '<span style="margin-right: 5px;" />'
      // title event
      const titleEvent = document.createElement('span')
      // eslint-disable-next-line no-underscore-dangle
      if (calendarEvent._def.title) {
        titleEvent.classList = 'title-event'
        // eslint-disable-next-line no-underscore-dangle
        titleEvent.innerHTML = calendarEvent._def.title
      }

      arrayOfDomNodes = [imgEventWrap, titleEvent]
      // eslint-disable-next-line no-shadow
      imgEventWrap.addEventListener('click', event => {
        event.stopPropagation()
        getCurrentEventData(calendarEvent)
        store.commit('calendar/SHOW_MESSAGE_HANDLER', true)
        store.commit('calendar/SHOW_MESSAGE_GANTT_HANDLER', false)
        // eslint-disable-next-line no-use-before-define
        isEventHandlerSidebarActive.value = true
        const popup = document.querySelector('.fc-popover')
        if (popup) {
          popup.style.display = 'none'
        }
        // console.log(store.state.calendar.sendMessage)
      })
      return { domNodes: arrayOfDomNodes }
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      // console.log(event.value)
      // ASSIGNING DEFAULT VALUE TO PREVENT UNDEFINED
      event.value.extendedProps.dailyPattern = { pattern: 'every', day: '1' }
      event.value.extendedProps.weeklyPattern = { every_week: '1', days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] }
      event.value.extendedProps.monthlyPattern = {
        pattern: 'pattern1',
        input_one: '',
        input_two: '',
        selected1: [],
        selected2: [],
        input_three: '',
      }
      event.value.extendedProps.yearlyPattern = {
        recur_every: '',
        pattern: 'pattern1',
        input_one: '',
        input_two: '',
        selected1: [],
        selected2: [],
        selected3: [],
        selected4: [],
      }

      // ASSIGNING RECURRENCE VALUE FORMAT
      if (clickedEvent.extendedProps.recurrence_pattern == 'daily') { // IF PATTERN IS DAILY
        if (clickedEvent.extendedProps.pattern_option.every == 'weekday') {
          event.value.extendedProps.dailyPattern = { pattern: clickedEvent.extendedProps.pattern_option.every, day: '' }
        } else {
          event.value.extendedProps.dailyPattern = { pattern: 'every', day: clickedEvent.extendedProps.pattern_option.every }
        }
      } else if (clickedEvent.extendedProps.recurrence_pattern == 'weekly') { // IF PATTERN IS WEEKLY
        event.value.extendedProps.weeklyPattern = { every_week: clickedEvent.extendedProps.pattern_option.every, days: clickedEvent.extendedProps.pattern_option.days }
      } else if (clickedEvent.extendedProps.recurrence_pattern == 'monthly') { // IF PATTERN IS MONTHLY
        if (clickedEvent.extendedProps.pattern_option.nth == undefined) {
          event.value.extendedProps.monthlyPattern = {
            pattern: 'pattern1',
            input_one: clickedEvent.extendedProps.pattern_option.day,
            input_two: clickedEvent.extendedProps.pattern_option.every,
          }
        } else {
          event.value.extendedProps.monthlyPattern = {
            pattern: 'pattern2',
            selected1: clickedEvent.extendedProps.pattern_option.nth,
            selected2: clickedEvent.extendedProps.pattern_option.day,
            input_three: clickedEvent.extendedProps.pattern_option.every,
          }
        }
      } else if (clickedEvent.extendedProps.recurrence_pattern == 'yearly') { // IF PATTERN IS YEARLY
        // eslint-disable-next-line no-lonely-if
        if (clickedEvent.extendedProps.pattern_option.nth == undefined) {
          const inputs = clickedEvent.extendedProps.pattern_option.specific_day.split('-')
          // console.log(inputs)
          event.value.extendedProps.yearlyPattern = {
            pattern: 'pattern1',
            recur_every: clickedEvent.extendedProps.pattern_option.every,
            selected1: inputs[0],
            input_two: inputs[1],
          }
        } else {
          event.value.extendedProps.yearlyPattern = {
            pattern: 'pattern2',
            recur_every: clickedEvent.extendedProps.pattern_option.every,
            selected2: clickedEvent.extendedProps.pattern_option.nth,
            selected3: clickedEvent.extendedProps.pattern_option.day,
            selected4: clickedEvent.extendedProps.pattern_option.month,
          }
        }
      } else {
        event.value.extendedProps.recurrence_pattern = 'daily'
      }
      event.value.extendedProps.statusCopy = event.value.extendedProps.status
      event.value.extendedProps.caregiverCopy = event.value.extendedProps.caregiver
      // console.log(event.value)
      store.commit('calendar/SHOW_MESSAGE_HANDLER', false)
      // eslint-disable-next-line no-use-before-define
      if (event.value.extendedProps.pending) {
        axios
          .post(`${Vue.prototype.$apiUrl2}calendar/getPendingCaregiverOptions`, {
            param: {
              shift_date: new Date(event.value.start).toLocaleDateString(),
              id: event.value.id,
            },
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
          .then(response => {
            pendingCaregiverOptions.value = response.data.response.data
          })
          .catch(error => {
            // console.log(error)
          })
        openRequestApproveModal(event)
      } else if (event.value.extendedProps.recurrence && !event.value.extendedProps.pending) {
        recurrType.value = 'single'
        // eslint-disable-next-line no-alert
        chooseRecurrModal.value.show()
      } else {
        event.value.extendedProps.recurr_type = ''
        isEventHandlerSidebarActive.value = true
        const popup = document.querySelector('.fc-popover')
        if (popup) {
          popup.style.display = 'none'
        }
      }
    },

    moreLinkClick(info) {
      // Fix popup overlay bug
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      dayGridDay: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'Day',
        click() {
          // eslint-disable-next-line no-use-before-define
          // console.log('Day')
          store.commit('calendar/SHOW_DAY_VIEW', true)
          store.commit('calendar/SHOW_WEEK_VIEW', false)
          document.getElementsByClassName('fc-view-harness')[0]
            .style.display = 'none'
          //   document.getElementsByClassName('custom-view')[0]
          //   .style.display = 'block'
          document.getElementsByClassName('fc-dayGridDay-button')[0]
            .style.setProperty('background-color', 'rgba(86, 144, 153, 0.2)', 'important')
          document.getElementsByClassName('fc-dayGridWeek-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-dayGridMonth-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-prev-button')[0]
            .style.display = 'none'
          document.getElementsByClassName('fc-next-button')[0]
            .style.display = 'none'
          document.getElementsByClassName('fc-toolbar-title')[0]
            .style.display = 'none'
        },
      },
      dayGridWeek: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'Week',
        click() {
          // eslint-disable-next-line no-use-before-define
          // console.log('Week')
          store.commit('calendar/SHOW_WEEK_VIEW', true)
          store.commit('calendar/SHOW_DAY_VIEW', false)
          document.getElementsByClassName('fc-view-harness')[0]
            .style.display = 'none'
          //   document.getElementsByClassName('custom-view')[0]
          //   .style.display = 'block'
          document.getElementsByClassName('fc-dayGridDay-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-dayGridWeek-button')[0]
            .style.setProperty('background-color', 'rgba(86, 144, 153, 0.2)', 'important')
          document.getElementsByClassName('fc-dayGridMonth-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-prev-button')[0]
            .style.display = 'none'
          document.getElementsByClassName('fc-next-button')[0]
            .style.display = 'none'
          document.getElementsByClassName('fc-toolbar-title')[0]
            .style.display = 'none'
        },
      },
      dayGridMonth: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'Month',
        click() {
          // eslint-disable-next-line no-use-before-define
          // console.log('Month')
          document.getElementsByClassName('fc-view-harness')[0]
            .style.display = 'block'
          // refetchEvents()
          //   document.getElementsByClassName('custom-view')[0]
          //   .style.display = 'none'
          store.commit('calendar/SHOW_WEEK_VIEW', false)
          store.commit('calendar/SHOW_DAY_VIEW', false)
          document.getElementsByClassName('fc-dayGridDay-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-dayGridWeek-button')[0]
            .style.setProperty('background-color', '#ffffff', 'important')
          document.getElementsByClassName('fc-dayGridMonth-button')[0]
            .style.setProperty('background-color', 'rgba(86, 144, 153, 0.2)', 'important')
          document.getElementsByClassName('fc-prev-button')[0]
            .style.display = 'block'
          document.getElementsByClassName('fc-next-button')[0]
            .style.display = 'block'
          document.getElementsByClassName('fc-toolbar-title')[0]
            .style.display = 'block'
          filterAction.value = true
          refetchEvents()
          filterAction.value = true
        },
      },
      prev: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: '<',
        click() {
          // console.log('prev')
          filterAction.value = true
          dontOverlay.value = true
          calendarApi.prev()
          currentDate.value = moment(calendarApi.getDate(), 'YYYY-MM-DD').format('YYYY-MM-DD')
          // console.log(currentDate.value)
        },
      },
      next: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: '>',
        click() {
          // console.log('next')
          filterAction.value = true
          dontOverlay.value = true
          calendarApi.next()
          currentDate.value = moment(calendarApi.getDate(), 'YYYY-MM-DD').format('YYYY-MM-DD')
          // console.log(currentDate.value)
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date, end: info.date })))
      event.value.extendedProps.recurr_type = ''
      store.commit('calendar/SHOW_MESSAGE_HANDLER', false)
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // -------------------------------FOR GANNT DAY AND WEEK VIEW START -------------------------------------------- //
  const ganttDayViewData = ref([])
  const ganttDataTemp = ref([])
  const temp = ref([])
  const fetchganntDayViewData = () => {
    if (filterGanntAction.value == true) {
      temp.value = []
      ganttDataTemp.value.forEach(t => {
        temp.value
          .push({
            id: t.id,
            client: t.client,
            email: t.email,
            firstname: t.firstname,
            lastname: t.lastname,
            middlename: t.middlename,
            barList: t.barList.filter(list => {
              // selectedCalendars.value.includes(list.status)
              let keep = true
              if (selectedCalendars.value) {
                keep = keep && selectedCalendars.value.includes(list.status)
              }
              if (filterByLocation.value != '') {
                keep = keep && list.location.includes(filterByLocation.value)
              }
              if (!Array.isArray(selected1.value) && selected1.value != null) {
                keep = keep && selected1.value.value == list.authorization
              }
              if (!Array.isArray(selected2.value) && Array.isArray(list.tags) && selected2.value != null) {
                keep = keep && list.tags.includes(selected2.value.value)
              }
              return keep
            }),
          })
      })
      ganttDayViewData.value = temp.value
      filterGanntAction.value = false
      // console.log(temp.value)
      // console.log(ganttDataTemp.value)
    } else {
      axios
        .post(`${Vue.prototype.$apiUrl2}calendar/ganttData`, {
          param: {
            calendars: selectedCalendars.value.join(','),
            filter1: filterByLocation.value,
            filter2: selected1.value,
            filter3: selected2.value,
            userEmail: uData.email,
          },
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(response => {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < response.data.response.data.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < response.data.response.data[i].barList.length; j++) {
              if (moment().format('YYYY-MM-DD') < moment().utc().format('YYYY-MM-DD')) {
                response.data.response.data[i].barList[j].myStart = moment(response.data.response.data[i].barList[j].start).add(1, 'days').format('YYYY-MM-DD HH:mm')
                response.data.response.data[i].barList[j].myEnd = moment(response.data.response.data[i].barList[j].end).add(1, 'days').format('YYYY-MM-DD HH:mm')
              } else {
                response.data.response.data[i].barList[j].myStart = moment(response.data.response.data[i].barList[j].start).format('YYYY-MM-DD HH:mm')
                response.data.response.data[i].barList[j].myEnd = moment(response.data.response.data[i].barList[j].end).format('YYYY-MM-DD HH:mm')
              }
            }
          }
          ganttDayViewData.value = response.data.response.data
          // console.log(response.data.response.data)

          if (filterGanntAction.value == false) {
            ganttDataTemp.value = response.data.response.data // Temp storage for all non-filtered data
            // console.log(ganttDataTemp.value)
          }
          filterGanntAction.value = false
        })
        .catch(error => {
          // console.log(error)
        })
    }
    // filterAction.value = false
  }
  fetchganntDayViewData()

  const sendMessage1 = () => {
    store.commit('calendar/SHOW_MESSAGE_HANDLER', false)
    // eslint-disable-next-line no-use-before-define
    isEventHandlerSidebarActive.value = true
  }

  const ganttAddShift = row => {
    event.value.start = event.value.end = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
    event.value.extendedProps.recurr_type = ''
    event.value.extendedProps.client = row
    // console.log()
    store.commit('calendar/SHOW_MESSAGE_HANDLER', false)
    // eslint-disable-next-line no-use-before-define
    isEventHandlerSidebarActive.value = true
  }

  const ganttSendMessage = row => {
    event.value.id = row.id
    event.value.extendedProps.caregiverEmail = row.caregiverEmail
    event.value.extendedProps.clientEmail = row.clientEmail
    store.commit('calendar/SHOW_MESSAGE_HANDLER', true)
    // store.commit('calendar/SHOW_MESSAGE_GANTT_HANDLER', true)
    // eslint-disable-next-line no-use-before-define
    isEventHandlerSidebarActive.value = true
  }

  const ganttEditShift = row => {
    // console.log(row)
    event.value.id = row.id
    event.value.start = row.start
    event.value.end = row.end
    event.value.allDay = row.allDay
    event.value.extendedProps.status = row.status
    event.value.extendedProps.statusCopy = row.status
    event.value.extendedProps.statusOptions = row.statusOptions
    event.value.extendedProps.recurrence_identifier = row.recurrence_identifier
    event.value.extendedProps.recurrence = row.recurrence
    event.value.extendedProps.repeat_every = row.repeat_every
    event.value.extendedProps.repeat_on = row.repeat_on
    event.value.extendedProps.repeat_until = row.repeat_until
    event.value.extendedProps.client = row.client
    event.value.extendedProps.caregiver = row.caregiver
    event.value.extendedProps.caregiverCopy = row.caregiver
    event.value.extendedProps.caregiver_fullname = row.caregiver_fullname
    event.value.extendedProps.client_fullname = row.client_fullname
    event.value.extendedProps.broadcast = row.broadcast
    event.value.extendedProps.confirmation = row.confirmation
    event.value.extendedProps.clientRate = row.clientRate
    event.value.extendedProps.authorization = row.authorization
    event.value.extendedProps.caregiverRate = row.caregiverRate
    event.value.extendedProps.location = row.location
    event.value.extendedProps.tags = row.tags
    event.value.extendedProps.shift_tasks = row.shift_tasks
    event.value.extendedProps.shift_type = row.shift_type
    event.value.extendedProps.orig_start = row.orig_start
    event.value.extendedProps.orig_end = row.orig_end

    // ASSIGNING DEFAULT VALUE TO PREVENT UNDEFINED
    event.value.extendedProps.dailyPattern = { pattern: 'every', day: '1' }
    event.value.extendedProps.weeklyPattern = { every_week: '1', days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] }
    event.value.extendedProps.monthlyPattern = {
      pattern: 'pattern1',
      input_one: '',
      input_two: '',
      selected1: [],
      selected2: [],
      input_three: '',
    }
    event.value.extendedProps.yearlyPattern = {
      recur_every: '',
      pattern: 'pattern1',
      input_one: '',
      input_two: '',
      selected1: [],
      selected2: [],
      selected3: [],
      selected4: [],
    }

    // ASSIGNING RECURRENCE VALUE FORMAT
    if (row.recurrence_pattern == 'daily') { // IF PATTERN IS DAILY
      if (row.pattern_option.every == 'weekday') {
        event.value.extendedProps.dailyPattern = { pattern: row.pattern_option.every, day: '' }
      } else {
        event.value.extendedProps.dailyPattern = { pattern: 'every', day: row.pattern_option.every }
      }
    } else if (row.recurrence_pattern == 'weekly') { // IF PATTERN IS WEEKLY
      event.value.extendedProps.weeklyPattern = { every_week: row.pattern_option.every, days: row.pattern_option.days }
    } else if (row.recurrence_pattern == 'monthly') { // IF PATTERN IS MONTHLY
      if (row.pattern_option.nth == undefined) {
        event.value.extendedProps.monthlyPattern = {
          pattern: 'pattern1',
          input_one: row.pattern_option.day,
          input_two: row.pattern_option.every,
        }
      } else {
        event.value.extendedProps.monthlyPattern = {
          pattern: 'pattern2',
          selected1: row.pattern_option.nth,
          selected2: row.pattern_option.day,
          input_three: row.pattern_option.every,
        }
      }
    } else if (row.recurrence_pattern == 'yearly') { // IF PATTERN IS YEARLY
      // eslint-disable-next-line no-lonely-if
      if (row.pattern_option.nth == undefined) {
        const inputs = row.pattern_option.specific_day.split('-')
        // console.log(inputs)
        event.value.extendedProps.yearlyPattern = {
          pattern: 'pattern1',
          input_one: row.pattern_option.every,
          selected1: inputs[0],
          input_two: inputs[1],
        }
      } else {
        event.value.extendedProps.yearlyPattern = {
          pattern: 'pattern2',
          input_one: row.pattern_option.every,
          selected2: row.pattern_option.nth,
          selected3: row.pattern_option.day,
          selected4: row.pattern_option.month,
        }
      }
    } else {
      event.value.extendedProps.recurrence_pattern = 'daily'
    }

    // console.log(event.value)
    store.commit('calendar/SHOW_MESSAGE_HANDLER', false)
    // eslint-disable-next-line no-use-before-define
    if (row.pending) {
      axios
        .post(`${Vue.prototype.$apiUrl2}calendar/getPendingCaregiverOptions`, {
          param: {
            shift_date: new Date(event.value.start).toLocaleDateString(),
            id: event.value.id,
          },
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(response => {
          pendingCaregiverOptions.value = response.data.response.data
        })
        .catch(error => {
          // console.log(error)
        })
      openRequestApproveModal(event)
    } else if (event.value.extendedProps.recurrence && !event.value.extendedProps.pending) {
      recurrType.value = 'single'
      // eslint-disable-next-line no-alert
      chooseRecurrModal.value.show()
    } else {
      event.value.extendedProps.recurr_type = ''
      isEventHandlerSidebarActive.value = true
      const popup = document.querySelector('.fc-popover')
      if (popup) {
        popup.style.display = 'none'
      }
    }
  }
  // -------------------------------FOR GANNT DAY AND WEEK VIEW END ---------------------------------------- //

  // ------------------------------------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    requestApproveModal,
    chooseRecurrModal,
    updateRecurrModal,
    continueUpdateSingleRecurr,
    recurrType,
    openRecurrShift,
    hideRequestApproveModal,
    openRequestApproveModal,
    approveRequest,
    pendingCaregiverOptions,
    toApproveCaregiver,
    currentDate,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    updateEventModal,
    sendMessage,
    removeEvent,
    refetchEvents,
    fetchEvents,
    allFetchedData,
    allTempFetchedData,
    ganttDayViewData,
    ganttDataTemp,
    fetchganntDayViewData,
    sendMessage1,
    ganttAddShift,
    ganttSendMessage,
    ganttEditShift,
    initializeSockJs,
    filterAction,

    checkCaregiverAvailability,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
