<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="$store.state.calendar.isSidebarLoading"
          variant="dark"
          opacity="0.30"
          rounded="lg"
        >
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              {{ (eventLocal.id && $store.state.calendar.sendMessage) ? 'Send a Message': eventLocal.id ? 'Update Shift' : 'Add Shift' }}
            </h5>
            <div>
              <feather-icon
                v-if="nextButton"
                class="ml-1 cursor-pointer"
                icon="ArrowLeftIcon"
                size="16"
                @click="nextButton = false; taskTimeState = false"
              />
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>

          <!-- Body -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- {{ eventLocal.id }} -->
              <!-------------------------------- V-IF -------------------------------->
              <div v-if="$store.state.calendar.sendMessage">
                <div>
                  <!-- Sent To -->
                  <validation-provider
                    #default="validationContext"
                    name="Sent To"
                    rules="required"
                  >
                    <b-form-group
                      label="Sent To"
                      label-for="sent-to"
                    >
                      <b-form-input
                        id="sent-to"
                        v-model="eventLocal.extendedProps.caregiverEmail"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Sent Replies To -->
                  <validation-provider
                    #default="validationContext"
                    name="Sent Replies To"
                    rules="required"
                  >
                    <b-form-group
                      label="Sent Replies To"
                      label-for="replies-to"
                    >
                      <b-form-input
                        id="replies-to"
                        v-model="eventLocal.extendedProps.userEmail"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    label="Relates To"
                    label-for="relates-to"
                  >
                    <v-select
                      v-model="eventLocal.extendedProps.clientEmail"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      placeholder="Add People"
                      :options="clientOptions"
                      :reduce="option => option.email"
                      input-id="client"
                    />
                  </b-form-group>

                  <!-- Subject -->
                  <b-form-group
                    label="Subject"
                    label-for="subject"
                  >
                    <b-form-input
                      id="subject"
                      v-model="eventLocal.extendedProps.subject"
                      trim
                    />
                  </b-form-group>

                  <!-- Message -->
                  <b-form-group
                    label="Message"
                    label-for="task-description"
                  >
                    <quill-editor
                      id="quill-content1"
                      v-model="eventLocal.extendedProps.message"
                      :options="editorOption1"
                      class="border-bottom-0"
                    />
                    <div
                      id="quill-toolbar1"
                      class="d-flex justify-content-end border-top-0"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-align" />
                      <button class="ql-link" />
                    </div>
                  </b-form-group>

                  <!-- Signature -->
                  <b-form-group
                    label="Signature "
                    label-for="task-description"
                  >
                    <quill-editor
                      id="quill-content2"
                      v-model="eventLocal.extendedProps.signature"
                      :options="editorOption2"
                      class="border-bottom-0"
                    />
                    <div
                      id="quill-toolbar2"
                      class="d-flex justify-content-end border-top-0"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-align" />
                      <button class="ql-link" />
                    </div>
                  </b-form-group>

                  <span class="text-signature">
                    Changes here will only apply to the current messages
                    Go to Agency Settings to change signature permanently.
                  </span>

                  <div class="mt-1">
                    <label for="files">Attach File/s</label>
                    <b-form-file
                      id="files"
                      v-model="eventLocal.extendedProps.files"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                    />
                  </div>
                <!-- {{ eventLocal.extendedProps.files }} -->
                </div>
              </div>

              <!---------------------------------------------------------------------- V-ELSE ----------------------------------------------------------------------->
              <div v-else>
                <div v-show="!nextButton">
                  <!-- Start Date -->
                  <validation-provider
                    #default="validationContext"
                    name="Start Date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start Date *"
                      label-for="start-date"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="eventLocal.start"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d G:i K'}"
                        :disabled="Boolean(eventLocal.allDay)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- End Date -->
                  <validation-provider
                    #default="validationContext"
                    name="End Date *"
                    rules="required"
                  >

                    <b-form-group
                      label="End Date"
                      label-for="end-date"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="eventLocal.end"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d G:i K'}"
                        :disabled="Boolean(eventLocal.allDay & !eventLocal.id) || Boolean(eventLocal.allDay)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- All Day -->
                  <b-form-group>
                    <b-form-checkbox
                      v-model="eventLocal.allDay"
                      name="check-button"
                      switch
                      inline
                      @change="checkAllDay(eventLocal.allDay)"
                    >
                      All Day
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- Select Status -->
                  <b-form-group
                    v-if="eventLocal.id"
                    label="Status"
                    label-for="status"
                  >
                    <v-select
                      v-model="eventLocal.extendedProps.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="eventLocal.extendedProps.statusOptions"
                      label="label"
                      :reduce="calendar => calendar.id"
                      input-id="status"
                      placeholder="Select Status"
                      :disabled="['2', '3', '4', '5', '7', '8'].includes(eventLocal.extendedProps.statusCopy)"
                    />
                  </b-form-group>
                  <!-- {{ eventLocal.extendedProps.recurr_type }} -->
                  <!-- Recurrence -->
                  <b-form-group
                    v-if="eventLocal.extendedProps.recurr_type === 'all' || eventLocal.extendedProps.recurr_type === ''"
                    label="Recurrence"
                    label-for="event-recurrence"
                  >
                    <b-form-checkbox
                      id="event-recurrence"
                      v-model="eventLocal.extendedProps.recurrence"
                      style="margin-top:10px;"
                    >
                      Make this a repeating event
                    </b-form-checkbox>
                  </b-form-group>

                  <div v-if="eventLocal.extendedProps.recurrence && (eventLocal.extendedProps.recurr_type === 'all' || eventLocal.extendedProps.recurr_type === '')">
                    <b-card class="recurr-card">
                      <b-form-group
                        id="recurr-pattern"
                        class="mt-1"
                        label="Recurrence Pattern"
                        label-for="recurrence-pattern"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.recurrence_pattern"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="Recurrence Pattern"
                          placeholder="Select Pattern"
                          :options="['daily', 'weekly', 'monthly', 'yearly']"
                        />
                      </b-form-group>
                      <!-- DAILY RECURRENCE -->
                      <div v-if="eventLocal.extendedProps.recurrence_pattern == 'daily' || eventLocal.extendedProps.recurrence_pattern == ''">
                        <b-form-group
                          class="mt-2 calendar-label"
                        >
                          <div>
                            <div class="demo-inline-spacing custom-calendar-radio">
                              <b-form-radio
                                v-model="eventLocal.extendedProps.dailyPattern.pattern"
                                name="daily-pattern"
                                value="every"
                              >
                                Every
                              </b-form-radio>
                              <b-form-radio
                                v-model="eventLocal.extendedProps.dailyPattern.pattern"
                                name="daily-pattern"
                                value="weekday"
                              >
                                Every Weekday
                              </b-form-radio>
                            </div>
                            <!-- EVERY FORM -->
                            <div v-if="eventLocal.extendedProps.dailyPattern.pattern === 'every'">
                              <validation-provider
                                #default="validationContext"
                                name="Every"
                                rules="required"
                              >
                                <b-form-group
                                  label="Every"
                                  label-for="daily-pattern-every"
                                  class="mt-1"
                                >
                                  <b-form-input
                                    id="daily-pattern-every"
                                    v-model="eventLocal.extendedProps.dailyPattern.day"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    placeholder=""
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </div>
                          </div>
                        </b-form-group>
                        <!-- {{ dailyPattern }} -->
                      </div><!-- DAILY RECURRENCE END -->

                      <!-- WEEKLY RECURRENCE -->
                      <div v-if="eventLocal.extendedProps.recurrence_pattern == 'weekly'">
                        <b-form-group
                          class="mt-1 calendar-label"
                        >
                          <!-- WeekLY FORM -->
                          <div>
                            <validation-provider
                              #default="validationContext"
                              name="Recur Every"
                              rules="required"
                            >
                              <b-form-group
                                label="Recur Every"
                                label-for="weekly-recur-every"
                              >
                                <b-form-input
                                  id="weekly-recur-every"
                                  v-model="eventLocal.extendedProps.dailyPattern.day"
                                  autofocus
                                  :state="getValidationState(validationContext)"
                                  trim
                                  placeholder=""
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </div>
                          <span>Weeks On:</span>
                          <b-form-checkbox-group
                            id="weekly-days"
                            v-model="eventLocal.extendedProps.weeklyPattern.days"
                            :options="weeklyPatternDays"
                            name="weekly-days"
                            class=""
                          />
                        </b-form-group>
                      </div><!-- WEEKLY RECURRENCE END -->
                      <!-- MONTHLY RECURRENCE -->
                      <div v-if="eventLocal.extendedProps.recurrence_pattern == 'monthly'">
                        <b-form-group
                          class="mt-2 calendar-label"
                        >
                          <div class="demo-inline-spacing custom-calendar-radio">
                            <b-form-radio
                              v-model="eventLocal.extendedProps.monthlyPattern.pattern"
                              name="monthly-pattern"
                              value="pattern1"
                            >Pattern 1
                            </b-form-radio>
                            <b-form-radio
                              v-model="eventLocal.extendedProps.monthlyPattern.pattern"
                              name="monthly-pattern"
                              value="pattern2"
                              class=""
                            >Pattern 2
                            </b-form-radio>
                          </div>
                          <div v-if="eventLocal.extendedProps.monthlyPattern.pattern === 'pattern1'">

                            <!-- MONTHLY DAY PATTERN 1 -->
                            <validation-provider
                              #default="validationContext"
                              name="Monthly Pattern 1 Day"
                              rules="required"
                            >
                              <b-form-group
                                label="Day"
                                label-for="monthly-input1"
                                class="mt-1"
                              >
                                <b-form-input
                                  id="monthly-input1"
                                  v-model="eventLocal.extendedProps.monthlyPattern.input_one"
                                  autofocus
                                  :state="getValidationState(validationContext)"
                                  trim
                                  placeholder="Day"
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                            <!-- MONTHLY DAY PATTERN 1 INPUT 2-->
                            <validation-provider
                              #default="validationContext"
                              name="Monthly Pattern 1 Month"
                              rules="required"
                            >
                              <b-form-group
                                label="Of Every:"
                                label-for="monthly-input2"
                                class="mt-1"
                              >
                                <b-form-input
                                  id="monthly-input2"
                                  v-model="eventLocal.extendedProps.monthlyPattern.input_two"
                                  autofocus
                                  :state="getValidationState(validationContext)"
                                  trim
                                  placeholder="Month(s)"
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </div>
                          <div v-else>
                            <!-- Select Nth Position -->
                            <validation-provider
                              #default="validationContext"
                              name="Monthly Pattern 2 Nth Position"
                              rules="required"
                            >

                              <b-form-group
                                label="Nth Position"
                                label-for="monthly-vselect1"
                                :state="getValidationState(validationContext)"
                                class="mt-1"
                              >
                                <v-select
                                  v-model="eventLocal.extendedProps.monthlyPattern.selected1"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :close-on-select="true"
                                  :options="monthlyPattern.option1"
                                  :reduce="option => option.value"
                                  input-id="monthly-vselect1"
                                />

                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                            <!-- Select Monthly Pattern 2 Day -->
                            <validation-provider
                              #default="validationContext"
                              name="Monthly Pattern 2 Day"
                              rules="required"
                            >

                              <b-form-group
                                label="Day"
                                label-for="monthly-vselect2"
                                :state="getValidationState(validationContext)"
                                class="mt-1"
                              >
                                <v-select
                                  v-model="eventLocal.extendedProps.monthlyPattern.selected2"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :close-on-select="true"
                                  :options="monthlyPattern.option2"
                                  :reduce="option => option.value"
                                  input-id="monthly-vselect2"
                                />

                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                            <!-- MONTHLY DAY PATTERN 2 INPUT 3-->
                            <validation-provider
                              #default="validationContext"
                              name="Monthly Pattern 2 Month"
                              rules="required"
                            >
                              <b-form-group
                                label="Of Every:"
                                label-for="monthly-input3"
                                class="mt-1"
                              >
                                <b-form-input
                                  id="monthly-input3"
                                  v-model="eventLocal.extendedProps.monthlyPattern.input_three"
                                  autofocus
                                  :state="getValidationState(validationContext)"
                                  trim
                                  placeholder="Month(s)"
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                          </div>
                        </b-form-group>
                        <!-- {{ monthlyPattern.selected2 }} -->
                      </div><!-- MONTHLY RECURRENCE END-->
                      <!-- YEARLY RECURRENCE -->
                      <div v-if="eventLocal.extendedProps.recurrence_pattern == 'yearly'">
                        <b-form-group
                          class="mt-2 calendar-label"
                        >
                          <div class="demo-inline-spacing custom-calendar-radio">
                            <b-form-radio
                              v-model="eventLocal.extendedProps.yearlyPattern.pattern"
                              name="yearly-pattern"
                              value="pattern1"
                              class=""
                            > Pattern 1
                            </b-form-radio>
                            <b-form-radio
                              v-model="eventLocal.extendedProps.yearlyPattern.pattern"
                              name="yearly-pattern"
                              value="pattern2"
                              class=""
                            > Pattern 2
                            </b-form-radio>
                          </div>
                        </b-form-group>
                        <div>
                          <validation-provider
                            #default="validationContext"
                            name="Recur Every"
                            rules="required"
                          >
                            <b-form-group
                              label="Recur Every"
                              label-for="weekly-recur-every"
                            >
                              <b-form-input
                                id="weekly-recur-every"
                                v-model="eventLocal.extendedProps.yearlyPattern.recur_every"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Year(s)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div v-if="eventLocal.extendedProps.yearlyPattern.pattern === 'pattern1'">

                          <!-- SELECT YEARLY PATTERN 1 MONTH -->
                          <validation-provider
                            #default="validationContext"
                            name="Yearly Pattern 1 Month"
                            rules="required"
                          >

                            <b-form-group
                              label="Month"
                              label-for="yearly-vselect1"
                              :state="getValidationState(validationContext)"
                              class="mt-1"
                            >
                              <v-select
                                v-model="eventLocal.extendedProps.yearlyPattern.selected1"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :close-on-select="true"
                                :options="yearlyPattern.option1"
                                :reduce="option => option.value"
                                input-id="yearly-vselect1"
                                placeholder="Month"
                              />

                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>

                          <!-- YEARLY DAY PATTERN 1 -->
                          <validation-provider
                            #default="validationContext"
                            name="Yearly Pattern 1 Day"
                            rules="required"
                          >
                            <b-form-group
                              label="Day"
                              label-for="yearly-input1"
                              class="mt-1"
                            >
                              <b-form-input
                                id="yearly-input1"
                                v-model="eventLocal.extendedProps.yearlyPattern.input_two"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Day"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>

                        </div>
                        <div v-else>

                          <!-- Select Nth Position -->
                          <validation-provider
                            #default="validationContext"
                            name="Yearly Pattern 2 Nth Position"
                            rules="required"
                          >

                            <b-form-group
                              label="On The:"
                              label-for="yearly-vselect2"
                              :state="getValidationState(validationContext)"
                              class="mt-1"
                            >
                              <v-select
                                v-model="eventLocal.extendedProps.yearlyPattern.selected2"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :close-on-select="true"
                                :options="yearlyPattern.option2"
                                :reduce="option => option.value"
                                input-id="yearly-vselect2"
                              />

                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>

                          <!-- Select Day -->
                          <validation-provider
                            #default="validationContext"
                            name="Yearly Pattern 2 Day"
                            rules="required"
                          >

                            <b-form-group
                              label="Day"
                              label-for="yearly-vselect3"
                              :state="getValidationState(validationContext)"
                              class="mt-1"
                            >
                              <v-select
                                v-model="eventLocal.extendedProps.yearlyPattern.selected3"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :close-on-select="true"
                                :options="yearlyPattern.option3"
                                :reduce="option => option.value"
                                input-id="yearly-vselect3"
                              />

                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>

                          <!-- Select Month -->
                          <validation-provider
                            #default="validationContext"
                            name="Yearly Pattern 2 Month"
                            rules="required"
                          >

                            <b-form-group
                              label="Month"
                              label-for="yearly-vselect4"
                              :state="getValidationState(validationContext)"
                              class="mt-1"
                            >
                              <v-select
                                v-model="eventLocal.extendedProps.yearlyPattern.selected4"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :close-on-select="true"
                                :options="yearlyPattern.option4"
                                :reduce="option => option.value"
                                input-id="yearly-vselect4"
                              />

                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div><!-- YEARLY RECURRENCE END -->

                      <!-- REPEAT UNTIL -->
                      <div>
                        <validation-provider
                          #default="validationContext"
                          name="Repeat Until"
                          rules="required"
                        >

                          <b-form-group
                            label="Repeat Until"
                            label-for="repeat-until"
                          >
                            <flat-pickr
                              v-model="eventLocal.extendedProps.repeat_until"
                              class="form-control"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </b-card>

                  </div>

                  <!-- Select Client -->
                  <validation-provider
                    #default="validationContext"
                    name="Client"
                    rules="required"
                  >

                    <b-form-group
                      label="Client"
                      label-for="event-client"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="eventLocal.extendedProps.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="clientOptions"
                        :reduce="option => option.id"
                        input-id="client"
                        placeholder="Select Client"
                        :disabled="['7'].includes(eventLocal.extendedProps.statusCopy)"
                        @input="getClientData"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Select Caregiver -->
                  <b-form-group
                    label="Caregiver"
                    label-for="event-caregiver"
                  >
                    <v-select
                      v-model="eventLocal.extendedProps.caregiver"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="caregiverOptions"
                      :reduce="option => option.id"
                      input-id="caregiver"
                      placeholder="Select Caregiver"
                      @input="checkCaregiverAvailability, (eventLocal.extendedProps.broadcast = !(eventLocal.extendedProps.caregiver > 0))"
                    />
                  </b-form-group>

                  <!-- Location -->
                  <b-form-group
                    label="Location"
                    label-for="event-location"
                  >
                    <b-form-input
                      id="event-location"
                      v-model="eventLocal.extendedProps.location"
                      autofocus
                      trim
                      placeholder="Select Location"
                    />
                  </b-form-group>

                  <!-- Mileage -->
                  <!-- <b-form-group
                label="Mileage"
                label-for="event-mileage"
              >
                <b-form-input
                  id="event-mileage"
                  v-model="eventLocal.extendedProps.mileage"
                  autofocus
                  trim
                  placeholder="# of miles"
                />
              </b-form-group> -->

                  <!-- Select Tags -->
                  <!-- <b-form-group
                    label="Tags"
                    label-for="tags"
                  >
                    <v-select
                      v-model="eventLocal.extendedProps.tags"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      :close-on-select="true"
                      :options="tagOptions"
                      :reduce="option => option.value"
                      input-id="tags"
                      placeholder="Select Tags"
                    />
                  </b-form-group> -->

                  <!-- Reason -->
                  <!-- <b-form-group
                label="Reason *"
                label-for="event-reason"
              >
                <quill-editor
                  id="quil-content"
                  v-model="eventLocal.extendedProps.reason"
                  :options="editorOption"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar"
                  class="d-flex justify-content-end border-top-0"
                >
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </b-form-group> -->
                </div>
                <div
                  v-show="nextButton"
                  style="min-height: 75vh"
                >
                  <b-form-group
                    :label="shiftTaskOptions.length > 0 ? 'Edit Task/s' : 'Add or Create New Task/s' "
                  >
                    <b-spinner
                      v-if="!isShiftTasksLoaded"
                      label="Spinning"
                      small
                    />
                    <!-- <b-form-checkbox
                  v-for="shiftTaskOption in shiftTaskOptions"
                  :key="shiftTaskOption.text"
                  v-model="selectedTask"
                  :value="shiftTaskOption.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
                >
                  {{ shiftTaskOption.text }}
                </b-form-checkbox> -->
                    <b-list-group
                      v-if="taskOptions"
                      flush
                    >
                      <b-list-group-item
                        v-for="taskOption in taskOptions"
                        :key="taskOption.id"
                        v-model="selectedTask"
                        class="d-flex justify-content-between text-truncate"
                      >
                        <span>
                          {{ stripTags(taskOption.description) }}
                        </span>
                        <span class="d-inline-block">
                          <feather-icon
                            :id="`row-${taskOption.id}-id-task-preview-icon`"
                            icon="EditIcon"
                            size="16"
                            class="mr-1 pe-auto"
                            role="button"
                            @click="createTask = true ; taskOption.action = 'edit'; caregiverTask = taskOption"
                          />
                          <b-tooltip
                            title="Edit"
                            :target="`row-${taskOption.id}-id-task-preview-icon`"
                          />

                          <!-- <feather-icon
                        :id="`row-${taskOption.id}-id-task-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        role="button"
                      />
                      <b-tooltip
                        :target="`row-${taskOption.id}-id-task-delete-icon`"
                        title="Delete"
                      /> -->
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                    <!-- {{ selectedTask }} -->
                    <!-- {{ taskOptions }} -->

                    <b-list-group
                      v-if="shiftTaskOptions"
                      flush
                    >
                      <b-list-group-item
                        v-for="(shiftTaskOption, index) in shiftTaskOptions"
                        :key="index"
                        v-model="selectedTask"
                        class="d-flex justify-content-between text-truncate"
                      >
                        <span>
                          {{ shiftTaskOption.task }}
                        </span>
                        <span class="d-inline-block">
                          <feather-icon
                            :id="`row-${shiftTaskOption.key}-id-shift-task-preview-icon`"
                            icon="EditIcon"
                            size="16"
                            class="mr-1 pe-auto"
                            role="button"
                            @click="createTask = true ; shiftTaskOption.action = 'edit'; caregiverTask = shiftTaskOption"
                          />
                          <b-tooltip
                            title="Edit"
                            :target="`row-${shiftTaskOption.key}-id-shift-task-preview-icon`"
                          />

                          <feather-icon
                            :id="`row-${shiftTaskOption.key}-id-shift-task-delete-icon`"
                            icon="TrashIcon"
                            size="16"
                            role="button"
                            @click="removeShifTask(index)"
                          />
                          <b-tooltip
                            :target="`row-${shiftTaskOption.key}-id-shift-task-delete-icon`"
                            title="Delete"
                          />
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                    <!-- {{ shiftTaskOptions }} -->
                  </b-form-group>
                  <!-- {{ eventLocal.extendedProps.shift_tasks }} -->
                  <p
                    v-if="!createTask && (eventLocal.extendedProps.shift_type === 'future' || !eventLocal.extendedProps.shift_type)"
                    class="d-inline mt-2 mb-2 create-task"
                    @click="createTask = !createTask; caregiverTask = {}"
                  >+ Create New Task</p>

                  <!-- OK BUTTON FOR ADDING TASKS TO JSON OBJECT -->
                  <b-button
                    v-if="createTask"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    class="d-inline float-right addtask"
                    @click="pushCaregiverTask(caregiverTask)"
                  >
                    OK
                  </b-button>

                  <!-- CANCEL BUTTON FOR ADDING TASKS TO JSON OBJECT -->
                  <b-button
                    v-if="createTask"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-dark"
                    class="d-inline float-right mr-1 addtask"
                    @click="createTaskCancel(); taskTimeState = false"
                  >
                    Cancel
                  </b-button>

                  <!-- IF CREATE TASK SHOW THE FORM -->
                  <div
                    v-if="createTask"
                    class="mt-3"
                  >
                    <!-- Task  Form-->
                    <div v-if="caregiverTask.description">
                      <!-- <h4 class="text-primary">
                    {{ stripTags(caregiverTask.description) }}
                  </h4> -->

                      <!-- task status  -->
                      <b-form-group
                        class="mt-1"
                        label="Task status"
                        label-for="task-to"
                      >
                        <v-select
                          v-model="caregiverTask.status"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="Status"
                          placeholder="Select Status"
                          :options="['inprogress', 'completed', 'wont-complete', 'not-completed']"
                        />
                      </b-form-group>

                      <!-- task note  -->
                      <b-form-group
                        label="Notes"
                        label-for="notes"
                      >
                        <b-form-textarea
                          v-model="caregiverTask.notes"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Mileage"
                      >
                        <label>
                          Form of transportation
                        </label>

                        <div class="demo-inline-spacing custom-calendar-radio">
                          <b-form-radio
                            v-model="caregiverTask.mileage_transpo"
                            name="mileage_transpo"
                            value="commuting"
                          >
                            Commuting
                          </b-form-radio>
                          <b-form-radio
                            v-model="caregiverTask.mileage_transpo"
                            name="mileage_transpo"
                            value="driving"
                          >
                            Driving
                          </b-form-radio>
                        </div>
                        <label>
                          How many miles did you travel?
                        </label>
                        <b-form-input
                          v-model="caregiverTask.mileage"
                          type="number"
                        />
                      </b-form-group>

                    </div>
                    <div v-else>
                      <!--Shift Task  Form-->
                      <validation-provider
                        #default="validationContext"
                        name="Task"
                        rules="required"
                      >
                        <b-form-group
                          label="Task"
                          label-for="task"
                        >
                          <b-form-input
                            id="task"
                            v-model="caregiverTask.task"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                          />
                          <!-- <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback> -->
                        </b-form-group>
                      </validation-provider>

                      <!-- Details -->
                      <div>
                        <label for="textarea-default">Details</label>
                        <b-form-textarea
                          id="textarea-default"
                          v-model="caregiverTask.details"
                          rows="2"
                        />
                      </div>

                      <!-- due Time -->
                      <div v-if="!taskTimeState && !caregiverTask.start_time">
                        <label
                          class="mt-2"
                          for="timepicker-valid"
                        >Start Time {{ caregiverTask.start_time }}</label>
                        <b-form-timepicker
                          id="datepicker-valid"
                          v-model="caregiverTask.start_time"
                          class="mb-2"
                        />
                      </div>
                      <div v-else>
                        <label
                          class="mt-2"
                          for="timepicker-valid"
                        >Start Time {{ caregiverTask.start_time }}</label>
                        <b-form-timepicker
                          id="datepicker-valid"
                          v-model="caregiverTask.start_time"
                          :state="caregiverTask.start_time ? true : false"
                          class="mb-2"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="!nextButton && !$store.state.calendar.sendMessage"
                class="d-flex mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="mr-2"
                  @click="clickNext"
                >
                  Next
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-dark"
                  @click="taskTimeState = false; hide()"
                >
                  Cancel
                </b-button>
              </div>
              <!-- Form Actions -->
              <div
                v-if="nextButton || $store.state.calendar.sendMessage"
                class="d-flex mt-3"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="mr-2"
                  type="submit"
                  @click="checkRequiredForms"
                >
                  {{ (eventLocal.id && $store.state.calendar.sendMessage) ? 'Send' : eventLocal.id ? 'Update' : 'Save' }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-dark"
                  @click="hide()"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BTooltip, BOverlay, BSpinner, BListGroup, BListGroupItem, BSidebar, BForm, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BFormCheckboxGroup, BButton, BFormInvalidFeedback, BFormFile, BFormTimepicker, BFormTextarea, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BTooltip,
    BOverlay,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BFormTimepicker,
    BFormRadio,
    BFormFile,
    BCard,
    // BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    allTempFetchedData: {
      type: Array,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const formObserver = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
      Authorization,
      repeatOptions,
      clientOptions,
      caregiverOptions,
      tagOptions,
      nextButton,
      clickNext,
      createTask,
      selectedTask,
      taskOptions,
      shiftTaskOptions,
      caregiverTask,
      pushCaregiverTask,
      taskTimeState,
      createTaskCancel,
      removeShifTask,
      isShiftTasksLoaded,
      // recurrence
      checkRequiredForms,
      weeklyPatternDays,
      monthlyPattern,
      yearlyPattern,
      checkClientAvailability,
      checkCaregiverAvailability,
      checkAllDay,
      getClientData,
      // validateShiftTasks,
    } = useCalendarEventHandler(toRefs(props), clearFormData, formObserver, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm
    formObserver.value = refFormObserver

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    }

    const editorOption1 = {
      modules: {
        toolbar: '#quill-toolbar1',
      },
      placeholder: 'Write your message',
    }

    const editorOption2 = {
      modules: {
        toolbar: '#quill-toolbar2',
      },
      placeholder: 'Write your signature',
    }

    const stripTags = html => {
      const tmp = document.implementation.createHTMLDocument('New').body
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      Authorization,
      repeatOptions,
      clientOptions,
      caregiverOptions,
      tagOptions,
      editorOption,
      editorOption1,
      editorOption2,
      nextButton,
      clickNext,
      createTask,
      selectedTask,
      taskOptions,
      shiftTaskOptions,
      caregiverTask,
      pushCaregiverTask,
      taskTimeState,
      createTaskCancel,
      removeShifTask,
      isShiftTasksLoaded,
      stripTags,
      // recurrence
      checkRequiredForms,
      weeklyPatternDays,
      monthlyPattern,
      yearlyPattern,
      checkClientAvailability,
      checkCaregiverAvailability,
      checkAllDay,
      getClientData,
      // validateShiftTasks,

      // Form Validation
      resetForm,
      refFormObserver,
      formObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar, #quill-toolbar1, #quill-toolbar2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
<style scoped>
  *>>> .custom-checkbox.custom-control {
    margin-top: 10px;
}
.create-task{
  color: #569099;
  cursor: pointer;
}
.addtask{
    padding: 0.486rem 0.8rem;
}
*>>> #recurr-pattern .vs__selected, *>>> #recurr-pattern li{
  text-transform: capitalize !important;
}
</style>
<style>
.quill-editor{
  height: 148px;
}
#quill-content2{
  height: 74px;
}
[dir] .ql-container.ql-snow {
    border-bottom: 0px;
}
.custom-calendar-radio .custom-radio{
  margin-top: 3px;
  margin-bottom: 5px;
  margin-right: 15px;
}
.custom-radio .custom-control-label{
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #33475B;
  opacity: 1;
  padding-top: 3px;
}
.calendar-label .bv-no-focus-ring{
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #33475B;
  opacity: 1;
  padding-top: 5px !important;
}
.text-signature{
  text-align: left;
  font: italic normal normal 10px/15px Montserrat;
  letter-spacing: 0px;
  color: #516F90;
}
.recurr-card{
  border: 1px solid #d8d6de;
}
[dir] .card-body{
  padding: 1rem;
}
.b-sidebar-body .position-absolute{
  position: fixed !important;
}
.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second, .flatpickr-time .flatpickr-am-pm {
    font-weight: bold !important;
}
</style>
