/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable space-in-parens */
/* eslint-disable no-empty */
/* eslint-disable no-lonely-if */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import moment from 'moment'
import Vue from 'vue'
import Swal from 'sweetalert2'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCalendarEventHandler(
  props,
  clearForm,
  formObserver,
  emit,
) {
  const allFetchedShiftData = ref([])
  allFetchedShiftData.value = computed(() => props.allTempFetchedData.value)

  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal0 = ref(JSON.parse(JSON.stringify(props.event.value)))
  // const eventLocal = computed(() => JSON.parse(JSON.stringify(props.event.value)))

  const eventLocal = computed({
    get: () => props.event.value,
    set: val => {
      eventLocal0.value = val
    },
  })
  // console.log(eventLocal.value)
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  // watch(props.event, () => {
  //   resetEventLocal()
  // })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
        resetNextButton()
        createTaskReset()
        caregiverTaskReset()
        shiftTaskOptions.value = []
        taskOptions.value = []
        taskTimeState.value = false
      }, 200)
    } else {
      getClientData()
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  let validationCount = 0

  // -----------------------------------------------------------------------------------------------------------------------------------
  // MAKE VALIDATIONS BEFORE SUBMITTING
  // -----------------------------------------------------------------------------------------------------------------------------------
  const onSubmit = () => {
    if (!store.state.calendar.sendMessage) store.commit('calendar/SHOW_SIDEBAR_OVERLAY', true)
    if (
      eventLocal.value.extendedProps.status == '8'
      && eventLocal.value.extendedProps.caregiver == ''
      // && !eventLocal.value.extendedProps.recurrence_identifier
      && !store.state.calendar.sendMessage
    ) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Please Select Caregiver! ',
          icon: 'XIcon',
          variant: 'danger',
        },
      },
      {
        timeout: 10000,
      })
      resetNextButton()
      validationCount += 1
      store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    } else if (
      moment(eventLocal.value.end, 'YYYY-MM-DD HH:mm:ss A') <= moment(eventLocal.value.start, 'YYYY-MM-DD HH:mm:ss A')
      // && !eventLocal.value.extendedProps.recurrence_identifier
      && !store.state.calendar.sendMessage
    ) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'End Date must be greater than start date. ',
          icon: 'XIcon',
          variant: 'danger',
        },
      },
      {
        timeout: 10000,
      })
      resetNextButton()
      validationCount += 1
      store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    } else if (
      moment(eventLocal.value.extendedProps.repeat_until).format('YYYY-MM-DD')
        < moment(eventLocal.value.end).format('YYYY-MM-DD')
      // && !eventLocal.value.extendedProps.recurrence_identifier
      && !store.state.calendar.sendMessage
    ) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Repeat Until date must be greater than end date. ',
          icon: 'XIcon',
          variant: 'danger',
        },
      },
      {
        timeout: 10000,
      })
      resetNextButton()
      validationCount += 1
      store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    } else if (
      shiftTaskOptions.value.length == 0
      && taskOptions.value.length == 0
      // && !eventLocal.value.extendedProps.recurrence_identifier
      && !store.state.calendar.sendMessage
    ) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Please Add Caregiver Task/s',
          icon: 'XIcon',
          variant: 'danger',
        },
      },
      {
        timeout: 10000,
      })
      resetNextButton()
      validationCount += 1
      store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    } else if (eventLocal.value.extendedProps.recurrence && eventLocal.value.extendedProps.recurr_type == 'all' && !store.state.calendar.sendMessage) {
      checkRecurShiftsAvailability().then(result => {
        if (!result) {
          resetNextButton()
          validationCount += 1
        } else doSubmit()
      })
    } else if ((!eventLocal.value.extendedProps.recurrence || eventLocal.value.extendedProps.recurr_type !== 'all') && !store.state.calendar.sendMessage) {
      // console.log('test 1')
      if (!checkClientAndCaregiverAvailability()) {
        resetNextButton()
        validationCount += 1
        store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
      } else doSubmit()
    } else {
      // console.log('test 2')
      // console.log(validationCount)
      if (validationCount == 0) {
        doSubmit()
      }
    }
  }

  // *===============================================---*
  // *--------- INITIALIZATIONS------------------------------------------------------------------------------------------------------------------------------------------------------------------------*
  // *===============================================---*

  const clientOptions = ref([])
  const caregiverOptions = ref([])
  const Authorization = ref([])
  const nextButton = ref(false)
  const shiftTaskOptions = ref([])
  const taskOptions = ref([])
  const createTask = ref(false)
  const selectedTask = ref([])
  const generatedRecurShifts = ref([])
  const taskTimeState = ref(false)
  const caregiverTask = ref({
    task: null,
    details: null,
    start_time: null,
  })
  const repeatOptions = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ]

  const tagOptions = [
    { label: '1st Call/Email', value: '1st-call-email' },
    { label: 'Accounting', value: 'accounting' },
    { label: 'Admin Communication', value: 'admin-communication' },
    { label: 'Background Check', value: 'background-check' },
    { label: 'Caregiver Call Off', value: 'caregiver-call-off' },
    { label: 'Caregiver Deactivation', value: 'caregiver-deactivation' },
  ]

  // RECURRENCE FUNCTIONS AND METHODS

  // FOR WEEKLY PATTERN DAYS CHECKBOX OPTIONS
  const weeklyPatternDays = [
    { text: 'Monday', value: 'monday' },
    { text: 'Tuesday', value: 'tuesday' },
    { text: 'Wednesday', value: 'wednesday' },
    { text: 'Thursday', value: 'thursday' },
    { text: 'Friday', value: 'friday' },
    { text: 'Saturday', value: 'saturday' },
    { text: 'Sunday', value: 'sunday' },
  ]

  // FOR MONTHLY PATTERN DROPDOWN STATIC VALUES
  const monthlyPattern = ref({
    option1: [
      { label: 'first', value: 'first' },
      { label: 'second', value: 'second' },
      { label: 'third', value: 'third' },
      { label: 'fourth', value: 'fourth' },
      { label: 'last', value: 'last' },
    ],
    option2: [
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
      { label: 'Sunday', value: 'sunday' },
    ],
  })

  // FOR YEARLY PATTERN
  const yearlyPattern = ref({
    option1: [
      { label: 'January', value: '01' },
      { label: 'February', value: '02' },
      { label: 'March', value: '03' },
      { label: 'April', value: '04' },
      { label: 'May', value: '05' },
      { label: 'June', value: '06' },
      { label: 'July', value: '07' },
      { label: 'August', value: '08' },
      { label: 'September', value: '09' },
      { label: 'October', value: '10' },
      { label: 'November', value: '11' },
      { label: 'December', value: '12' },
    ],
    option2: [
      { label: 'first', value: 'first' },
      { label: 'second', value: 'second' },
      { label: 'third', value: 'third' },
      { label: 'fourth', value: 'fourth' },
      { label: 'last', value: 'last' },
    ],
    option3: [
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
      { label: 'Sunday', value: 'sunday' },
    ],
    option4: [
      { label: 'January', value: 'january' },
      { label: 'February', value: 'february' },
      { label: 'March', value: 'march' },
      { label: 'April', value: 'april' },
      { label: 'May', value: 'may' },
      { label: 'June', value: 'june' },
      { label: 'July', value: 'july' },
      { label: 'August', value: 'august' },
      { label: 'September', value: 'september' },
      { label: 'October', value: 'october' },
      { label: 'November', value: 'november' },
      { label: 'December', value: 'december' },
    ],
  })

  // loaders
  const isShiftTasksLoaded = ref(false)

  // *===============================================---*
  // *--------- API REQUESTS START --------------------------------------------------------------------------------------------------------------------------------------------------------*
  // *===============================================---*

  // GET ASSIGNEE DATA FOR VUE SELECT
  axios
    .post(
      `${Vue.prototype.$apiUrl2}calendar/getSelect`,
      {
        param: {},
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    )
    .then(response => {
      clientOptions.value = response.data.response.data.client
      caregiverOptions.value = response.data.response.data.caregiver
      Authorization.value = response.data.response.data.administration
      // console.log(caregiverOptions.value)
    })
    .catch(error => {
      // console.log(error)
    })

  const getClientCareplanData = () => {
    isShiftTasksLoaded.value = false
    // console.log(eventLocal.value.id)
    if (!eventLocal.value.id) {
      // console.log('make req')
      shiftTaskOptions.value = []
      eventLocal.value.extendedProps.shift_tasks = []
      axios
        .post(
          `${Vue.prototype.$apiUrl2}calendar/getClientCareplanData`,
          {
            param: {
              clientId: eventLocal.value.extendedProps.client,
            },
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          },
        )
        .then(response => {
          // console.log(response.data.response)
          eventLocal.value.extendedProps.shift_tasks = response.data.response.data
          shiftTaskOptions.value = eventLocal.value.extendedProps.shift_tasks
          if (response.data.response.status == 200) {
            isShiftTasksLoaded.value = true
            // eslint-disable-next-line no-alert
            // alert(isShiftTasksLoaded.value)
          }
        })
        .catch(error => {
          // console.log(error)
        })
    } else isShiftTasksLoaded.value = true
  }

  const getClientAddress = () => {
    axios
      .post(
        `${Vue.prototype.$apiUrl2}calendar/getClientAddress`,
        {
          clientId: eventLocal.value.extendedProps.client,
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        },
      )
      .then(response => {
        // console.log(response.data.response.data)
        eventLocal.value.extendedProps.location = response.data.response.data
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const getPreferredCaregiver = () => {
    // console.log(eventLocal.value.id)
    axios
      .post(
        `${Vue.prototype.$apiUrl2}calendar/getPreferredCaregiver`,
        {
          param: {
            clientId: eventLocal.value.extendedProps.client,
            caregiverList: caregiverOptions.value,
          },
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        },
      )
      .then(response => {
        // console.log(response.data.response.data.caregiver_data)
        caregiverOptions.value = response.data.response.data.caregiver_data.sort(
          (a, b) => b.count - a.count,
        )
        // console.log(caregiverOptions.value)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  // *===============================================---*
  // *--------- CUSTOM FUNCTIONS --------------------------------------------------------------------------------------------------------------------------------------------------------*
  // *===============================================---*
  const doSubmit = () => {
    // console.log(validationCount)
    if (!store.state.calendar.sendMessage) setPatternOption()
    eventLocal.value.extendedProps.shift_tasks = shiftTaskOptions.value.length == 0
      ? taskOptions.value
      : shiftTaskOptions.value
    eventLocal.value.start = moment(eventLocal.value.start, 'YYYY-MM-DD HH:mm:ss A').format('YYYY-MM-DD HH:mm:ss')
    eventLocal.value.end = moment(eventLocal.value.end, 'YYYY-MM-DD HH:mm:ss A').format('YYYY-MM-DD HH:mm:ss')
    const eventData = eventLocal
    // console.log(eventData.value)

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id && store.state.calendar.sendMessage) {
      emit('send-message', eventData.value)
      caregiverTaskReset()
    } else if (props.event.value.id) {
      if (eventData.value.extendedProps.recurr_type === 'single') {
        emit('update-event-modal', eventData.value)
      } else emit('update-event', eventData.value)
      caregiverTaskReset()
    } else {
      emit('add-event', eventData.value)
      caregiverTaskReset()
    }

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
    store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    validationCount = 0
  }

  const setPatternOption = () => {
    if (eventLocal.value.extendedProps.recurrence_pattern == 'daily') {
      if (eventLocal.value.extendedProps.dailyPattern.pattern == 'every') {
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.dailyPattern.day,
        }
      } else {
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.dailyPattern.pattern,
        }
      }
    } else if (eventLocal.value.extendedProps.recurrence_pattern == 'weekly') {
      eventLocal.value.extendedProps.pattern_option = {
        every: eventLocal.value.extendedProps.weeklyPattern.every_week,
        days: eventLocal.value.extendedProps.weeklyPattern.days,
      }
    } else if (
      eventLocal.value.extendedProps.recurrence_pattern == 'monthly'
    ) {
      if (
        eventLocal.value.extendedProps.monthlyPattern.pattern == 'pattern1'
      ) {
        eventLocal.value.extendedProps.pattern_option = {
          day: eventLocal.value.extendedProps.monthlyPattern.input_one,
          every: eventLocal.value.extendedProps.monthlyPattern.input_two,
        }
      } else {
        eventLocal.value.extendedProps.pattern_option = {
          nth: eventLocal.value.extendedProps.monthlyPattern.selected1,
          day: eventLocal.value.extendedProps.monthlyPattern.selected2,
          every: eventLocal.value.extendedProps.monthlyPattern.input_three,
        }
      }
    } else {
      if (eventLocal.value.extendedProps.yearlyPattern.pattern == 'pattern1') {
        if (eventLocal.value.extendedProps.yearlyPattern.input_two < 10) {
          eventLocal.value.extendedProps.yearlyPattern.input_two = `0${eventLocal.value.extendedProps.yearlyPattern.input_two}`
        }
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.yearlyPattern.recur_every,
          specific_day: `${eventLocal.value.extendedProps.yearlyPattern.selected1}-${eventLocal.value.extendedProps.yearlyPattern.input_two}`,
        }
      } else {
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.yearlyPattern.recur_every,
          nth: eventLocal.value.extendedProps.yearlyPattern.selected2,
          day: eventLocal.value.extendedProps.yearlyPattern.selected3,
          month: eventLocal.value.extendedProps.yearlyPattern.selected4,
        }
      }
    }
  }

  const resetNextButton = () => {
    nextButton.value = false
  }
  const clickNext = e => {
    e.preventDefault()
    nextButton.value = true
    shiftTaskOptions.value = []
    taskOptions.value = []
    isShiftTasksLoaded.value = true
    if (
      eventLocal.value.extendedProps.shift_type == 'future'
      || !eventLocal.value.extendedProps.shift_type
    ) {
      // console.log(eventLocal.value.extendedProps.shift_tasks)
      // console.log(eventLocal.value.start)
      // eslint-disable-next-line camelcase
      const default_time = eventLocal.value.start.split(' ')
      // eslint-disable-next-line camelcase
      const shifts_tasks = eventLocal.value.extendedProps.shift_tasks.map(
        obj => {
          if (obj.start_time == '') {
            return { ...obj, start_time: `${default_time[1]}:00` }
          }
          return obj
        },
      )
      // console.log(shifts_tasks)
      // eslint-disable-next-line camelcase
      eventLocal.value.extendedProps.shift_tasks = shifts_tasks
      // eslint-disable-next-line camelcase
      shiftTaskOptions.value = shifts_tasks
    } else {
      taskOptions.value = eventLocal.value.extendedProps.shift_tasks
    }
  }
  const createTaskReset = () => {
    createTask.value = false
  }

  const caregiverTaskReset = () => {
    caregiverTask.value = {}
  }

  const createTaskCancel = () => {
    createTask.value = false
    caregiverTask.value = {}
  }

  const pushCaregiverTask = task => {
    if (validateShiftTasks()) {
      taskTimeState.value = false
      // console.log('task', task)
      if (task.details == undefined || task.details == null) {
        task.details = task.task
      }
      if (task.start_time == undefined || task.start_time == null) {
        // eslint-disable-next-line camelcase
        const start_time = eventLocal.value.start.split(' ')
        task.start_time = `${start_time[1]}:00`
      }
      if (task.task && task.details && task.start_time) {
        if (task.action != 'edit') {
          shiftTaskOptions.value.push(task)
        }
      } else if (task.description) {
      }
      // console.log('task--------------------------', task)
      caregiverTaskReset()
      createTaskReset()
    } else {
      taskTimeState.value = true
    }
  }

  const removeShifTask = ShifTask => {
    // console.log(shiftTaskOptions.value)
    // const tempShiftsTask = shiftTaskOptions.value.filter(
    //   f => f.task != ShifTask.task,
    // )
    const tempShiftsTask = shiftTaskOptions.value.slice(0, ShifTask).concat(shiftTaskOptions.value.slice(ShifTask + 1, shiftTaskOptions.value.length))
    shiftTaskOptions.value = tempShiftsTask
    eventLocal.value.extendedProps.shift_tasks = shiftTaskOptions.value
  }

  const checkRequiredForms = () => {
    // console.log(formObserver.value.value)
    formObserver.value.value.validate().then(success => {
      if (!success) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Please complete all the required forms! ',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        resetNextButton()
      }
    })
  }

  const checkClientAndCaregiverAvailability = () => {
    // console.log(eventLocal.value.extendedProps)
    // store.commit('calendar/SHOW_SIDEBAR_OVERLAY', true)
    const htmlText = []
    const shiftData = Object.values(
      allFetchedShiftData.value.value,
    ).filter(
      FetchedShiftData => ((FetchedShiftData.caregiver == eventLocal.value.extendedProps.caregiver) || (FetchedShiftData.client == eventLocal.value.extendedProps.client))
        && FetchedShiftData.id != eventLocal.value.id
        && FetchedShiftData.status != '10'
        && FetchedShiftData.status != '8'
        && FetchedShiftData.status != '7'
        && FetchedShiftData.status != '3'
        && (FetchedShiftData.caregiver != '' || FetchedShiftData.caregiver != 0)
        && ((moment(FetchedShiftData.start) <= moment(eventLocal.value.start)
          && moment(eventLocal.value.start) < moment(FetchedShiftData.end))
          || (moment(FetchedShiftData.start) < moment(eventLocal.value.end)
            && moment(eventLocal.value.end) < moment(FetchedShiftData.end))
          || (moment(eventLocal.value.start) < moment(FetchedShiftData.start)
            && moment(eventLocal.value.end) > moment(FetchedShiftData.end))),
    )
    // console.log(shiftData)
    shiftData.forEach(shift => {
      // console.log(shift.id)
      htmlText.push(
        `<p>${moment(shift.start).format(
          'MMM D, Y hh:mm A',
        )} - ${moment(shift.end).format(
          'MMM D, Y hh:mm A',
        )}</p>`,
      )
    })
    if (shiftData.length != 0) {
      Swal.fire({
        html: `<h4>Found Conflict Schedule/s on</h4><br> <div style='color: red;font-size: 12px;'>${htmlText.join(' ')}</div>`,
        showConfirmButton: false,
        showCloseButton: true,
        showClass: {
          popup: 'animate__animated animate__fadeIn animate__slower',
        },
      })
      store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
      return false
    }
    store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
    return true
  }

  const checkRecurShiftsAvailability = () => {
    // console.log(eventLocal.value)
    setPatternOption()
    // store.commit('calendar/SHOW_SIDEBAR_OVERLAY', true)
    const conflictData = []
    const htmlText = []
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Vue.prototype.$apiUrl2}calendar/validateRecurrence`,
          {
            param: {
              event: {
                id: eventLocal.value.id,
                start: eventLocal.value.start,
                end: eventLocal.value.end,
                repeat_until: eventLocal.value.extendedProps.repeat_until,
                pattern_option: eventLocal.value.extendedProps.pattern_option,
                recurrence_pattern: eventLocal.value.extendedProps.recurrence_pattern,
              },
              // allData: allFetchedShiftData.value.value,
            },
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          },
        )
        .then(response => {
          generatedRecurShifts.value = response.data.response.data
          generatedRecurShifts.value.forEach(shift => {
            // eslint-disable-next-line no-prototype-builtins
            // if (shift.hasOwnProperty('id')) {
            // console.log(shift)
            // }
            // console.log(eventLocal.value.id)
            const caregiverShiftDataRecur = Object.values(
              allFetchedShiftData.value.value,
            ).filter(
              FetchedShiftData => ((FetchedShiftData.caregiver == eventLocal.value.extendedProps.caregiver) || (FetchedShiftData.client == eventLocal.value.extendedProps.client))
                && FetchedShiftData.id != eventLocal.value.id
                && FetchedShiftData.status != '10'
                && FetchedShiftData.status != '8'
                && FetchedShiftData.status != '7'
                && FetchedShiftData.status != '3'
                && (FetchedShiftData.caregiver != '' || FetchedShiftData.caregiver != 0)
                && ((moment(FetchedShiftData.start) <= moment(shift.start)
                  && moment(shift.start) < moment(FetchedShiftData.end))
                  || (moment(FetchedShiftData.start) < moment(shift.end)
                    && moment(shift.end) < moment(FetchedShiftData.end))
                  || (moment(shift.start) < moment(FetchedShiftData.start)
                    && moment(shift.end) > moment(FetchedShiftData.end))),
            )
            // console.log(caregiverShiftDataRecur)
            for (let i = 0; i < caregiverShiftDataRecur.length; i += 1) {
              if (caregiverShiftDataRecur[i] != undefined) {
                conflictData.push(caregiverShiftDataRecur[i])
                htmlText.push(`<p>${moment(caregiverShiftDataRecur[i].start).format('MMM D, Y hh:mm A')} - ${moment(caregiverShiftDataRecur[i].end).format('MMM D, Y hh:mm A')}</p>`)
              }
            }
          })
          // console.log('testingCaregiver', conflictData)
          if (conflictData.length != 0) {
            // eslint-disable-next-line camelcase
            Swal.fire({
              html: `<h4>Found Conflict Schedule/s on</h4><br> <div style='color: red;font-size: 12px;'>${htmlText.join(' ')}</div>`,
              showConfirmButton: false,
              showCloseButton: true,
              showClass: {
                popup: 'animate__animated animate__fadeIn animate__slower',
              },
            })
            store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
            resolve(false)
          } else {
            store.commit('calendar/SHOW_SIDEBAR_OVERLAY', false)
            resolve(true)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const checkAllDay = all => {
    // console.log(all)
    if (all) {
      eventLocal.value.start = moment(
        eventLocal.value.start,
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD')
      eventLocal.value.end = moment(eventLocal.value.start, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD')
      // console.log(eventLocal.value.end)
    }
  }

  const getClientData = () => {
    // console.log('getting client data')
    getPreferredCaregiver()
    getClientCareplanData()
    getClientAddress()
  }

  const validateShiftTasks = () => {
    // eslint-disable-next-line camelcase
    const temp_time = eventLocal.value.start.split(' ')
    // console.log(temp_time)
    // eslint-disable-next-line camelcase
    const task_start_time = `${temp_time[0]} ${caregiverTask.value.start_time}`
    // eslint-disable-next-line camelcase
    const shift_start_time = `${eventLocal.value.start}:00`
    // eslint-disable-next-line camelcase
    if (
      (moment(task_start_time).isAfter(moment(eventLocal.value.start))
        && !moment(task_start_time).isAfter(moment(eventLocal.value.end)))
      // eslint-disable-next-line camelcase
      || task_start_time == shift_start_time
      || eventLocal.value.extendedProps.shift_type == 'previous'
    ) {
      return true
      // eslint-disable-next-line no-else-return
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title:
            'Shift tasks start time must be between shift start time and end time.',
          icon: 'XIcon',
          variant: 'danger',
        },
      },
      {
        timeout: 10000,
      })
      return false
    }
  }

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,

    // UI
    clientOptions,
    caregiverOptions,
    Authorization,
    repeatOptions,
    tagOptions,
    onSubmit,
    nextButton,
    clickNext,
    resetNextButton,
    createTask,
    createTaskReset,
    selectedTask,
    shiftTaskOptions,
    taskOptions,
    isShiftTasksLoaded,
    caregiverTask,
    pushCaregiverTask,
    taskTimeState,
    // caregiver
    checkRequiredForms,
    weeklyPatternDays,
    monthlyPattern,
    yearlyPattern,
    caregiverTaskReset,
    createTaskCancel,
    removeShifTask,
    checkClientAndCaregiverAvailability,
    checkRecurShiftsAvailability,
    checkAllDay,
    getClientData,
    validateShiftTasks,
    setPatternOption,
    generatedRecurShifts,
    doSubmit,
  }
}
