/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import store from '@/store'
import axios from '@axios'
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'

export default function useCalendarSidebar() {
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed({
    get: () => store.state.calendar.selectedCalendars,
    set: val => {
      store.commit('calendar/SET_SELECTED_EVENTS', val)
    },
  })

  // ------------------------------------------------
  // checkAll
  // ------------------------------------------------
  const checkAll = computed({
    /*
      GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
      SET: If value is `true` => then add all available options in selected filters => Select All
           Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
    */
    get: () => selectedCalendars.value.length == calendarOptions.value.length,
    set: val => {
      if (val) {
        selectedCalendars.value = calendarOptions.value.map(i => i.id)
      } else if (selectedCalendars.value.length == calendarOptions.value.length) {
        selectedCalendars.value = []
      }
    },
  })

  // filters
  // const filterByLocation = computed(() => store.state.calendar.filterByLocation)
  // const filterByLocation = computed({
  //   get: () => store.state.calendar.filterByLocation,
  //   set: val => {
  //     store.commit('calendar/SET_SELECTED_FILTERLOCATION', val)
  //   },
  // })
  // eslint-disable-next-line no-undef
  // const assignedStaff = ref([])

  // GET ASSIGNEE DATA FOR VUE SELECT
  // axios
  //   .post(`${Vue.prototype.$apiUrl2}calendar/getSelect`, {
  //     param: {
  //       type: 'assignedStaff',
  //     },
  //   },
  //   {
  //     headers: {
  //       'Content-type': 'application/json',
  //     },
  //   })
  //   .then(response => {
  //     assignedStaff.value = response.data.response.data.assignedStaff
  //     // caregiverOptions.value = response.data.response.data.caregiver
  //     // console.log(caregiverOptions.value)
  //   })
  //   .catch(error => {
  //     // console.log(error)
  //   })

  // const option1 = computed(() => assignedStaff.value)

  // const selected1 = computed({
  //   get: () => store.state.calendar.selected1,
  //   set: val => {
  //     store.commit('calendar/SET_SELECTED_FILTERSTAFF', val)
  //   },
  // })

  // const option2 = computed(() => store.state.calendar.option2)
  // const selected2 = computed({
  //   get: () => store.state.calendar.selected2,
  //   set: val => {
  //     store.commit('calendar/SET_SELECTED_FILTERTAG', val)
  //   },
  // })
  // const selected1 = []
  // const selected2 = []
  return {
    calendarOptions,
    selectedCalendars,
    checkAll,
    // assignedStaff,
    // filterByLocation,
    // selected1,
    // option1,
    // selected2,
    // option2,
  }
}
