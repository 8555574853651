/* eslint-disable eqeqeq */
/* eslint-disable no-const-assign */
import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        id: '1',
        class: 'scheduled',
        label: 'Scheduled',
      },
      {
        id: '2',
        class: 'in-progress',
        label: 'In Progress',
      },
      {
        id: '3',
        class: 'completed',
        label: 'Completed',
      },
      {
        id: '4',
        class: 'm-clock-in',
        label: 'Missed Clock-in',
      },
      {
        id: '5',
        class: 'm-clock-out',
        label: 'Missed Clock-out',
      },
      {
        id: '6',
        class: 'open-shift',
        label: 'Open Shift',
      },
      {
        id: '7',
        class: 'cancelled-client',
        label: 'Cancelled by Client',
      },
      {
        id: '8',
        class: 'cancelled-caregiver',
        label: 'Cancelled by Caregiver',
      },
      // {
      //   id: '9',
      //   class: 'tentative-not-echeduled',
      //   label: 'Tentative/Not Scheduled',
      // },
      {
        id: '10',
        class: 'pending-confirmation',
        label: 'Pending Confirmation',
      },
      // {
      //   id: '11',
      //   class: 'attention-required',
      //   label: 'Attention Required',
      // },
    ],
    filterByLocation: '',
    selectedCalendars: ['1', '2', '3', '4', '5', '6', '7', '8', '10'],
    option1: [
      { name: 'Darwin Francia', value: '1' },
      { name: 'Niko Isugan', value: '2' },
      { name: 'Jon Doe', value: '3' },
      { name: 'Henelyn Cruz', value: '4' },
      { name: 'Gilbert Fragata', value: '5' },
    ],
    selected1: [],
    option2: [
      { tag: '1st Call/Email', value: '1st-call-email' },
      { tag: 'Accounting', value: 'accounting' },
      { tag: 'Admin Communication', value: 'admin-communication' },
      { tag: 'Background Check', value: 'background-check' },
      { tag: 'Caregiver Call Off', value: 'caregiver-call-off' },
      { tag: 'Caregiver Deactivation', value: 'caregiver-deactivation' },
    ],
    selected2: [],
    sendMessage: false,
    sendMessageGantt: false,
    ganntDayView: false,
    ganntWeekView: false,
    isLoading: false,
    isSidebarLoading: false,
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_FILTERLOCATION(state, val) {
      state.filterByLocation = val
    },
    SET_SELECTED_FILTERSTAFF(state, val) {
      state.selected1 = val
    },
    SET_SELECTED_FILTERTAG(state, val) {
      state.selected2 = val
    },
    SHOW_MESSAGE_HANDLER(state, val) {
      state.sendMessage = val
    },
    SHOW_MESSAGE_GANTT_HANDLER(state, val) {
      state.sendMessageGantt = val
    },
    SHOW_DAY_VIEW(state, val) {
      state.ganntDayView = val
    },
    SHOW_WEEK_VIEW(state, val) {
      state.ganntWeekView = val
    },
    SHOW_OVERLAY(state, val) {
      state.isLoading = val
    },
    SHOW_SIDEBAR_OVERLAY(state, val) {
      state.isSidebarLoading = val
    },
  },
  actions: {
    fetchEvents(ctx, payload) {
      if (payload.filterAction == true) {
        // console.log(!Array.isArray(payload.filter2))
        // console.log(!Array.isArray(payload.filter3))
        // console.log(payload.filter2.value)
        // console.log(payload)
        const data = {
          data: {
            response: {
              data: payload.allDataTemp.filter(allDataTemp => {
                let keep = true
                if (payload.status) {
                  keep = keep && payload.status.includes(allDataTemp.status)
                }
                if (payload.filter1 != '') {
                  keep = keep && allDataTemp.location.toLowerCase().includes(payload.filter1.toLowerCase())
                }
                if (!Array.isArray(payload.filter2) && payload.filter2 != null) {
                  keep = keep && payload.filter2.value == allDataTemp.authorization
                }
                if (!Array.isArray(payload.filter3) && Array.isArray(allDataTemp.tags) && payload.filter3 != null) {
                  keep = keep && allDataTemp.tags.includes(payload.filter3.value)
                }
                return keep
              }), // FILTER THE STATUS
            },
          },
        }
        return data
      // eslint-disable-next-line no-else-return
      } else {
        return new Promise((resolve, reject) => {
          axios
            .post(`${Vue.prototype.$apiUrl2}calendar/fetchEvents`, {
              param: payload,
            },
            {
              headers: {
                'Content-type': 'application/json',
              },
            })
            .then(response => {
              // console.log(response)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    },
    addEvent(ctx, { event }) {
      const uData = localStorage.getItem('userData')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}calendar/addEvent`, {
            param: {
              shiftEvent: event,
              userData: JSON.parse(uData),
            },
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      const uData = localStorage.getItem('userData')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}calendar/updateEvent`, {
            param: {
              shiftEvent: event,
              userData: JSON.parse(uData),
            },
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { sendMessageData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}calendar/testCalendar`, {
            param: sendMessageData,
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
