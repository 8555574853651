<template>
  <div>
    <div
      v-if="rowList"
      id="ganttastic-wrapper"
    >
      <g-gantt-chart
        :chart-start="chartStart"
        :chart-end="chartEnd"
        :grid="grid"
        :hide-timeaxis="hideTimeaxis"
        :push-on-overlap="pushOnOverlap"
        :highlighted-hours="highlightedHours"
        :row-label-width="`${rowLabelWidth}%`"
        :row-height="rowHeight"
        :theme="selectedTheme"
      >
        <g-gantt-row
          v-for="row in rowList"
          :id="row.id"
          :key="row.title"
          :label="row.client"
          :bars="row.barList"
          :highlight-on-hover="highlightOnHover"
          bar-start="myStart"
          bar-end="myEnd"
        >
          <template #bar-label="{bar}">
            <span
              class="label-span"
              @click.self="editShift(bar)"
            >
              <i
                class="fas fa-envelope"
                @click="sendMessage(bar)"
              />
              <span
                class="caregiver-label"
                @click="editShift(bar)"
              >{{ bar.caregiver_fullname }}</span>
            </span>
          </template>
        </g-gantt-row>
      </g-gantt-chart>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import { GGanttChart, GGanttRow } from 'vue-ganttastic'
// eslint-disable-next-line no-unused-vars
import { onMounted, ref, computed } from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'

export default {
  components: {
    GGanttChart,
    GGanttRow,
  },
  props: {
    ganttDayViewData: {
      type: Array,
      required: true,
    },
  },
  unmounted() {
    this.style.remove()
  },
  setup(props, { emit }) {
    // console.log(props.ganttDayViewData)
    onMounted(() => {
      const c = document.querySelector('.g-timeaxis-empty-space')
      c.innerHTML = 'Clients'

      // ADD EVENT TO ROW
      const rows = document.querySelectorAll('.g-gantt-row')
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
        // eslint-disable-next-line no-use-before-define
        const clientData = rowList.value.filter(client => client.id == row.id)
        const imgEventWrap = document.createElement('span')
        imgEventWrap.innerHTML = clientData[0].profile_picture != '' ? `<img class="user-img" src="${Vue.prototype.$mainUrl}upload/clients/${clientData[0].profile_picture}">` : '<i class="fas fa-user"></i>'
        row.childNodes[0].appendChild(imgEventWrap)
        row.childNodes[0].addEventListener('click', () => {
          // eslint-disable-next-line no-undef
          emit('gantt-add-event', row.id)
        })
        row.childNodes[2].addEventListener('click', () => {
          // eslint-disable-next-line no-undef
          emit('gantt-add-event', row.id)
        })
      }
      setTimeout(() => {
        const days = document.querySelectorAll('.g-timeaxis-day')
        // console.log(days[0].childNodes[0])
        const date = new Date()
        days[0].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').format('ddd M/D')
        days[1].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(1, 'days').format('ddd M/D')
        days[2].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(2, 'days').format('ddd M/D')
        days[3].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(3, 'days').format('ddd M/D')
        days[4].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(4, 'days').format('ddd M/D')
        days[5].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(5, 'days').format('ddd M/D')
        days[6].childNodes[0].innerHTML = moment(new Date(date.setDate(date.getDate() - date.getDay())), 'YYYY-MM-DD').add(6, 'days').format('ddd M/D')
      }, 100)

      // Get all div elements with the class .g-gantt-bar
      const divElements = document.querySelectorAll('div.g-gantt-bar')

      // Add event listeners for mouseover and mouseout
      divElements.forEach(divElement => {
        let divWidth
        const originalBackgroundColor = divElement.style.background
        divElement.addEventListener('mouseover', () => {
          // Get the computed width of the div
          divWidth = window.getComputedStyle(divElement).getPropertyValue('width')

          // Set the computed width as the min-width
          // eslint-disable-next-line no-param-reassign
          divElement.style.width = 'fit-content'
          // eslint-disable-next-line no-param-reassign
          divElement.style.minWidth = divWidth
          // eslint-disable-next-line no-param-reassign
          divElement.style.background = 'white'
          // eslint-disable-next-line no-param-reassign
          divElement.style.zIndex = '99999'
          // eslint-disable-next-line no-param-reassign
          divElement.style.paddingRight = '14px'
        })

        divElement.addEventListener('mouseout', () => {
          // const divWidth = window.getComputedStyle(divElement).getPropertyValue('width')
          // eslint-disable-next-line no-param-reassign
          divElement.style.width = divWidth
          // eslint-disable-next-line no-param-reassign
          divElement.style.minWidth = ''
          // eslint-disable-next-line no-param-reassign
          divElement.style.background = originalBackgroundColor
          // eslint-disable-next-line no-param-reassign
          divElement.style.zIndex = '1'
          // eslint-disable-next-line no-param-reassign
          divElement.style.paddingRight = ''
        })
      })
    })
    const currentDate = new Date()
    const chartStart = `${new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())).toISOString().slice(0, 10)} 00:00`
    const chartEnd = `${new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7)).toISOString().slice(0, 10)} 00:00`
    // const chartStart = '2021-11-02 00:00'
    // const chartEnd = '2021-11-09 00:00'
    const pushOnOverlap = false
    const grid = false
    const rowHeight = 40
    const rowLabelWidth = 15
    const hideTimeaxis = false
    const highlightOnHover = true
    const hours = [...Array(24).keys()]
    const highlightedHours = [10, 12]
    const showContextmenu = false
    const contextmenuTimeout = null
    const contextmenuX = 0
    const contextmenuY = 0
    const selectedTheme = 'default'
    const themes = [
      'default',
      'vue',
      'dark',
      'material-blue',
      'creamy',
      'slumber',
      'sky',
      'crimson',
      'grove',
      'fuchsia',
      'flare',
    ]
    const rowList = ref(computed(() => props.ganttDayViewData))
    // console.log(rowList.value)
    // FUNCTIONS HERE

    const sendMessage = row => {
      // console.log(row)
      emit('gantt-send-message', row)
    }

    const editShift = row => {
      // console.log(row)
      emit('gantt-edit-shift', row)
    }

    return {
      chartStart,
      chartEnd,
      pushOnOverlap,
      grid,
      rowHeight,
      rowLabelWidth,
      hideTimeaxis,
      highlightOnHover,
      hours,
      highlightedHours,
      showContextmenu,
      contextmenuTimeout,
      contextmenuX,
      contextmenuY,
      selectedTheme,
      themes,
      rowList,
      sendMessage,
      editShift,
    }
  },
  methods: {
  },
}
</script>

<style scoped>
  #ganttastic-wrapper{
    height: 50vh;
    overflow-y: auto;
  }
  *>>> .g-timeaxis-hour{
    display: none !important;
  }
  *>>> .g-timeaxis-day>div[data-v-4cda9c60]:nth-child(2){
  display: none;
  font: normal normal 600 13px/16px Montserrat !important;
  }
  *>>> .g-timeaxis-day>div[data-v-4cda9c60]:nth-child(1){
    height: 100% !important;
    font: normal normal 600 13px/16px Montserrat !important;
  }
  .fa-envelope{
    font-size: 12px !important;
    font-family: "Font Awesome 5 Free" !important;
    /* margin-right: 11px !important; */
    overflow: unset !important;
  }
  *>>> .fas {
    font-family: "Font Awesome 5 Free" !important;
  }
  *>>> .fa-user,
  *>>> .user-img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #82868B;
    color: #FFFFFF;
    border-radius: 3px;
  }
  *>>> #g-timeaxis>:nth-child(1){
    height: 45px !important;
    text-align: center;
    padding: 10px;
    background: #E0E0E0;
    color: rgb(64, 64, 64);
    font-weight: bold;
    font: normal normal 600 14px/24px Montserrat !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  *>>> #g-timeaxis[data-v-4cda9c60]{
    min-height:45px !important;
    box-shadow: unset;
  }
  .label-span{
    padding-left: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .caregiver-label{
    margin-left: 14px;
    font-family: Montserrat !important;
  }
</style>
<style>
  .g-gantt-row{
    height: 60px !important;
    border: 1px solid #eaeaea !important;
    /* padding-top: 10px; */
  }
  .g-gantt-row>.g-gantt-row-label[data-v-419c73a4]{
    color: #569099 !important;
    justify-content: left !important;
    flex-direction: row-reverse !important;
    padding: 5px !important;
    font-weight: 500 !important;
    font: normal normal normal 14px/24px Montserrat !important;
  }
  .g-gantt-bar{
    height: 30px;
    margin-top: 15px !important;
    border-radius: 3px !important;
  }
  /* .g-gantt-bar:hover{
    width: fit-content !important;
    padding-right: 14px;
    background-color: white !important;
    z-index: 999999 !important;
  } */
  .g-gantt-row-bars-container{
    border: 0px!important;
  }
  #g-timeaxis-marker[data-v-4cda9c60]{
    width: 0px !important;
  }
  #ganttastic-wrapper[data-v-1b2d45d5] {
    height: unset;
    min-height: 50vh !important;
    max-height: 200vh !important;
  }
</style>
